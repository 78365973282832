import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import {
  agriCropIncome,
  agriCropExpense,
  agriCropArea,
  agriCashCropIncome,
  agriCashCropExpense,
  agriCashCropArea,
  agriDaalIncome,
  agriDaalExpense,
  agriDaalArea,
  agriFilungeySaleQuantity,
  agriFruitIncome,
  agriFruitExpense,
  agriFruitArea,
  agriMakaiSaleQuantity,
  agriOilIncome,
  agriOilExpense,
  agriOilArea,
  agriVegetableIncome,
  agriVegetableExpense,
  agriVegetableArea,
  agriDhanProductionKg,
  agriDhanSaleKg,
  agriMakaiProductionKg,
  agriMakaiSaleKg,
  agriKodoProductionKg,
  agriKodoSaleKg,
  agriFaparProductionKg,
  agriFaparSaleKg,
  agriGahuProductionKg,
  agriGahuSaleKg,
  agriAlaichiProductionKg,
  agriAlaichiSaleKg,
  agriRudrakshProductionKg,
  agriRudrakshSaleKg,
  agriAmrisoProductionKg,
  agriAmrisoSaleKg,
  agriCoffeeProductionKg,
  agriCoffeeSaleKg,
  agriTeaProductionKg,
  agriTeaSaleKg,
  agriGingerProductionKg,
  agriGingerSaleKg,
  agriGarlicProductionKg,
  agriGarlicSaleKg,
  agriMushroomProductionKg,
  agriMushroomSaleKg,
  agriSugarcaneProductionKg,
  agriSugarcaneSaleKg,
  agriNutProductionKg,
  agriNutSaleKg,
  agriChillyProductionKg,
  agriChillySaleKg,
  agriSimiProductionKg,
  agriSimiSaleKg,
  agriBlackDaalProductionKg,
  agriBlackDaalSaleKg,
  agriMusuriProductionKg,
  agriMusuriSaleKg,
  agriChanaProductionKg,
  agriChanaSaleKg,
  agriBhatmasProductionKg,
  agriBhatmasSaleKg,
  agriToriProductionKg,
  agriToriSaleKg,
  agriAalashProductionKg,
  agriAalashSaleKg,
  agriFilungeyProductionKg,
  agriFilungeySaleKg,
  agriSunflowerProductionKg,
  agriSunflowerSaleKg,
  agriPotatoProductionKg,
  agriPotatoSaleKg,
  agriCabbageProductionKg,
  agriCabbageSaleKg,
  agriTomatoProductionKg,
  agriTomatoSaleKg,
  agriCucumberProductionKg,
  agriCucumberSaleKg,
  agriGhiraulaProductionKg,
  agriGhiraulaSaleKg,
  agriSaagProductionKg,
  agriSaagSaleKg,
  agriMangoProductionKg,
  agriMangoSaleKg,
  agriBananaProductionKg,
  agriBananaSaleKg,
  agriOrangeProductionKg,
  agriOrangeSaleKg,
  agriAppleProductionKg,
  agriAppleSaleKg,
  agriKiwiProductionKg,
  agriKiwiSaleKg,
  agriAvogadoProductionKg,
  agriAvogadoSaleKg,
  agriFlowerProductionKg,
  agriFlowerSaleKg
} from "../../../../variable/agriculture";
import {
  aalashAreaQuestion,
  aalashProduction,
  aalashSale,
  aarubakhadaAreaQuestion,
  aarubakhadaProduction,
  aarubakhadaSale,
  agriBlackDaalSale,
  seedNeedQuestion,
  seedNeed,
  agriCropType,
  agriDaalTitle,
  agriDaalType,
  agriDhanAreaQuestion,
  agriDhanProduction,
  agriDhanSale,
  agriFaparAreaQuestion,
  agriFaparProduction,
  agriFaparSale,
  agriFruitTitle,
  agriFruitType,
  agriKodoAreaQuestion,
  agriKodoProduction,
  agriKodoSale,
  agriMakaiAreaQuestion,
  agriMakaiProduction,
  agriMakaiSale,
  agriOilTitle,
  agriOilType,
  agriProduction,
  agriProductionType,
  agriProductionTypeQuestion,
  agriTypeProduction,
  agriVegetableTitle,
  agriVegetableType,
  agriYesNo,
  alaichiAreaQuestion,
  alaichiProduction,
  alaichiSale,
  amrisoAreaQuestion,
  amrisoProduction,
  amrisoSale,
  appleAreaQuestion,
  appleProduction,
  appleSale,
  avogadoAreaQuestion,
  avogadoProduction,
  avogadoSale,
  badelMeatIncome,
  badelMeatProduction,
  badelNumber,
  bananaAreaQuestion,
  bananaProduction,
  bananaSale,
  beefishsilkQuestion,
  beefishsilkType,
  beefishsilkYes,
  beefishsilkYesNo,
  beeProduction,
  beeTitle,
  besaarAreaQuestion,
  besaarProduction,
  besaarSale,
  bhatmasAreaQuestion,
  bhatmasProduction,
  bhatmasSale,
  birdDiffType,
  birdTitle,
  blackDaalAreaQuestion,
  blackDaalProduction,
  buffalloMilkProduction,
  buffalloMilkProductionSale,
  buffalloMilkSale,
  buffalloNumber,
  cabbageAreaQuestion,
  cabbageProductionQuantity,
  cabbageSaleQuantity,
  cashCropTitle,
  cashCropType,
  cauliAreaQuestion,
  cauliProductionQuantity,
  cauliSaleQuantity,
  chanaAreaQuestion,
  chanaProduction,
  chanaSale,
  chillyAreaQuestion,
  chillyProduction,
  chillySale,
  chyangraMeatIncome,
  chyangraNumber,
  chyangraProduction,
  coffeeProduction,
  coffeeSale,
  coffeeTreeNumber,
  cowMilkProduction,
  cowMilkProductionSale,
  cowMilkSale,
  cowNumber,
  cucumberAreaQuestion,
  cucumberProductionQuantity,
  cucumberSaleQuantity,
  dhanyaAreaQuestion,
  dhanyaProductionQuantity,
  dhanyaSaleQuantity,
  duckEggSale,
  duckMeatIncome,
  duckMeatProduction,
  duckNumber,
  filungeAreaQuestion,
  filungeProduction,
  filungeSale,
  fishProduction,
  fishTitle,
  flowerAreaQuestion,
  flowerProduction,
  flowerSale,
  flowerSaleQuantity,
  gadhaMeatIncome,
  gadhaNumber,
  gadhaProduction,
  gahatAreaQuestion,
  gahatProduction,
  gahatSale,
  garlicAreaQuestion,
  garlicProduction,
  garlicSale,
  ghiraulaAreaQuestion,
  ghiraulaProductionQuantity,
  ghiraulaSaleQuantity,
  gingerAreaQuestion,
  gingerProduction,
  gingerSale,
  goatMeatIncome,
  goatNumber,
  guvaAreaQuestion,
  guvaProduction,
  guvaSale,
  henEggSale,
  henMeatIncome,
  henMeatProduction,
  henNumber,
  hybridChickenInfo,
  hybridMeatProduction,
  hybridNumber,
  hybridEggSale,
  horseNumber,
  insecticideQuestion,
  insecticideType,
  insecticideYes,
  insecticideYesNo,
  irrigationForLand,
  irrigationForLandList,
  khasiMeatIncome,
  khasiMeatProduction,
  khasiNumber,
  kiwiAreaQuestion,
  kiwiProduction,
  kiwiSale,
  landforAgri,
  landforAgriList,
  landforAgriYes,
  landforAgriYesNo,
  laukaAreaQuestion,
  laukaProductionQuantity,
  laukaSaleQuantity,
  lemonAreaQuestion,
  lemonProduction,
  lemonSale,
  livestockDiffType,
  livestockQuestion,
  livestockTitle,
  livestockType,
  livestockYes,
  livestockYesNo,
  mangoAreaQuestion,
  mangoProduction,
  mangoSale,
  mashyamAreaQuestion,
  mashyamProduction,
  mashyamSale,
  matarkoshaAreaQuestion,
  matarkoshaProductionQuantity,
  matarkoshaSaleQuantity,
  mushroomAreaQuestion,
  mushroomProduction,
  mushroomSale,
  musuriAreaQuestion,
  musuriProduction,
  musuriSale,
  naspatiAreaQuestion,
  naspatiProduction,
  naspatiSale,
  nutAreaQuestion,
  nutProduction,
  nutSale,
  onionAreaQuestion,
  onionProduction,
  onionSale,
  orangeAreaQuestion,
  orangeProduction,
  orangeSale,
  ostrichMeatIncome,
  ostrichMeatProduction,
  ostrichNumber,
  otherLand,
  ownLand,
  oxMeatIncome,
  oxNumber,
  pegion,
  pegionMeatIncome,
  pigMeatIncome,
  pigMeatProduction,
  pigNumber,
  pondArea,
  potatoAreaQuestion,
  potatoProductionQuantity,
  potatoSaleQuantity,
  raharAreaQuestion,
  raharProduction,
  raharSale,
  rangaMeatIncome,
  rangaMeatProduction,
  rangaNumber,
  reshamProduction,
  reshamTitle,
  saagAreaQuestion,
  saagProductionQuantity,
  saagSaleQuantity,
  sheepMeatIncome,
  sheepMeatProduction,
  sheepNumber,
  simiAreaQuestion,
  simiProductionQuantity,
  simiSaleQuantity,
  sugarcaneAreaQuestion,
  sugarcaneProduction,
  sugarcaneSale,
  sunflowerAreaQuestion,
  sunflowerProduction,
  sunflowerSale,
  suryaAreaQuestion,
  suryaProduction,
  suryaSale,
  teaAreaQuestion,
  teaProduction,
  teaSale,
  tilAreaQuestion,
  tilProduction,
  tilSale,
  tomatoAreaQuestion,
  tomatoProductionQuantity,
  tomatoSaleQuantity,
  tooriAreaQuestion,
  tooriProduction,
  tooriSale,
  totalBeeFishSilkIncome,
  totallandAreaTitle,
  yakMeatIncome,
  yakMilkProduction,
  yakNumber,
  yakProduction
} from "../../../../variable/houseSurvey";
import BoxBorder from "../../../common/frequent/BoxBorder";
import CheckBox from "../../../common/frequent/CheckBox";
import RadioButton from "../../../common/frequent/RadioButton";
import TextField from "../../../common/frequent/TextField";

class AgriLivestock extends Component {
  handleChange = (name, value) => {
    this.props.handleChange(`agriLivestock.${name}`, value);
  };
  render() {
    const { agriLivestock } = this.props;

    return (
      <Grid container direction="column">
        <Grid container>
          <RadioButton
            question={landforAgri}
            handleChange={this.handleChange}
            isVisible
            name="landforAgriYesNo"
            radioLists={landforAgriYesNo}
            value={agriLivestock.landforAgriYesNo}
          />
        </Grid>
        <BoxBorder
          isVisible={
            agriLivestock.landforAgriYesNo &&
            agriLivestock.landforAgriYesNo.includes("land_used_yes")
          }
        >
          <Grid container>
            <RadioButton
              question={irrigationForLand}
              name="irrigationForLandList"
              value={agriLivestock.irrigationForLandList}
              radioLists={irrigationForLandList}
              isVisible
              handleChange={this.handleChange}
            />
          </Grid>

          <Grid container>
            <TextField
              question={ownLand}
              name="agriOwnTotalRopani"
              type="number"
              placeholder="रोपनी"
              value={agriLivestock.agriOwnTotalRopani}
              isVisible
              handleChange={this.handleChange}
            />
          </Grid>
          <Grid xs={6}>
            <TextField
              name="agriOwnTotalAana"
              type="number"
              placeholder="आना"
              value={agriLivestock.agriOwnTotalAana}
              isVisible
              handleChange={this.handleChange}
            />
          </Grid>
          <Grid xs={6}>
            <TextField
              name="agriOwnTotalPaisa"
              type="number"
              placeholder="पैसा"
              value={agriLivestock.agriOwnTotalPaisa}
              isVisible
              handleChange={this.handleChange}
            />
          </Grid>

          <Grid container>
            <TextField
              question={otherLand}
              name="agriOtherTotalRopani"
              type="number"
              placeholder="रोपनी"
              value={agriLivestock.agriOtherTotalRopani}
              isVisible
              handleChange={this.handleChange}
            />
          </Grid>
          <Grid xs={6}>
            <TextField
              name="agriOtherTotalAana"
              type="number"
              placeholder="आना"
              value={agriLivestock.agriOtherTotalAana}
              isVisible
              handleChange={this.handleChange}
            />
          </Grid>
          <Grid xs={6}>
            <TextField
              name="agriOtherTotalPaisa"
              type="number"
              placeholder="पैसा"
              value={agriLivestock.agriOtherTotalPaisa}
              isVisible
              handleChange={this.handleChange}
            />
          </Grid>
        </BoxBorder>

        <Grid container>
          <RadioButton
            question={agriProduction}
            handleChange={this.handleChange}
            isVisible
            name="agriYesNo"
            radioLists={agriYesNo}
            value={agriLivestock.agriYesNo}
          />
        </Grid>
        <BoxBorder
          isVisible={
            agriLivestock.agriYesNo &&
            agriLivestock.agriYesNo.includes("agri_sales_yes")
          }
        >
          <Grid container>
            <CheckBox
              question={agriProductionTypeQuestion}
              name="agriProductionType"
              value={agriLivestock.agriProductionType}
              checkList={agriProductionType}
              isVisible
              handleCompChange={this.handleChange}
            />
          </Grid>

          <BoxBorder
            isVisible={
              agriLivestock.agriProductionType &&
              agriLivestock.agriProductionType.includes("crops")
            }
          >
            <Grid container>
              <CheckBox
                question={agriCropType}
                name="agriCropType"
                value={agriLivestock.agriCropType}
                checkList={agriCropType}
                isVisible
                handleCompChange={this.handleChange}
              />
            </Grid>
            <Grid container>
              <TextField
                question={agriCropIncome}
                name="agriCropIncome"
                type="number"
                placeholder="अन्नवाली खेतीबाट हुने बार्षिक आम्दानि कति हो ? (रु.)"
                value={agriLivestock.cropsIncome}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
            <Grid container>
              <TextField
                question={agriCropExpense}
                name="agriCropExpense"
                type="number"
                placeholder="अन्नवाली खेतीमा हुने बार्षिक लागत खर्च कति हुन्छ? (रु.)"
                value={agriLivestock.cropsExpense}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
            <Grid container>
              <TextField
                question={otherLand}
                name="cropsRopani"
                type="number"
                placeholder="रोपनी"
                value={agriLivestock.cropsRopani}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
            <Grid xs={6}>
              <TextField
                name="cropsAana"
                type="number"
                placeholder="आना"
                value={agriLivestock.cropsAana}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
            <Grid xs={6}>
              <TextField
                name="cropsPaisa"
                type="number"
                placeholder="पैसा"
                value={agriLivestock.cropsPaisa}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
          </BoxBorder>
          <BoxBorder
            isVisible={
              agriLivestock.agriCropType &&
              agriLivestock.agriCropType.includes("paddy")
            }
          >
            <Grid container>
              <TextField
                question={agriDhanProductionKg}
                name="agriDhanProductionKg"
                type="number"
                placeholder="के. जि"
                value={agriLivestock.agriDhanProductionKg}
                isVisible
                handleChange={this.handleChange}
              />
              <TextField
                question={agriDhanSaleKg}
                name="agriDhanSaleKg"
                type="number"
                placeholder="के. जि"
                value={agriLivestock.agriDhanSaleKg}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
          </BoxBorder>

          <BoxBorder
            isVisible={
              agriLivestock.agriCropType &&
              agriLivestock.agriCropType.includes("maize")
            }
          >
            <Grid container>
              <TextField
                question={agriMakaiProductionKg}
                name="agriMakaiProductionKg"
                type="number"
                placeholder="के. जि"
                value={agriLivestock.agriMakaiProductionKg}
                isVisible
                handleChange={this.handleChange}
              />
              <TextField
                question={agriMakaiSaleKg}
                name="agriMakaiSaleKg"
                type="number"
                placeholder="के. जि"
                value={agriLivestock.agriMakaiSaleKg}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
          </BoxBorder>
          <BoxBorder
            isVisible={
              agriLivestock.agriCropType &&
              agriLivestock.agriCropType.includes("kodo")
            }
          >
            <Grid container>
              <TextField
                question={agriKodoProductionKg}
                name="agriKodoProductionKg"
                type="number"
                placeholder="के. जि"
                value={agriLivestock.agriKodoProductionKg}
                isVisible
                handleChange={this.handleChange}
              />
              <TextField
                question={agriKodoSaleKg}
                name="agriKodoSaleKg"
                type="number"
                placeholder="के. जि"
                value={agriLivestock.agriKodoSaleKg}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
          </BoxBorder>
          <BoxBorder
            isVisible={
              agriLivestock.agriCropType &&
              agriLivestock.agriCropType.includes("fapar")
            }
          >
            <Grid container>
              <TextField
                question={agriFaparProductionKg}
                name="agriFaparProductionKg"
                type="number"
                placeholder="के. जि"
                value={agriLivestock.agriFaparProductionKg}
                isVisible
                handleChange={this.handleChange}
              />
              <TextField
                question={agriFaparSaleKg}
                name="agriFaparSaleKg"
                type="number"
                placeholder="के. जि"
                value={agriLivestock.agriFaparSaleKg}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
          </BoxBorder>

          <BoxBorder
            isVisible={
              agriLivestock.agriCropType &&
              agriLivestock.agriCropType.includes("gahu")
            }
          >
            <Grid container>
              <TextField
                question={agriGahuProductionKg}
                name="agriGahuProductionKg"
                type="number"
                placeholder="के. जि"
                value={agriLivestock.agriGahuProductionKg}
                isVisible
                handleChange={this.handleChange}
              />
              <TextField
                question={agriGahuSaleKg}
                name="agriGahuSaleKg"
                type="number"
                placeholder="के. जि"
                value={agriLivestock.agriGahuSaleKg}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
          </BoxBorder>

          <BoxBorder
            isVisible={
              agriLivestock.agriProductionType &&
              agriLivestock.agriProductionType.includes("daal")
            }
          >
            <Grid container>
              <CheckBox
                question={agriDaalTitle}
                name="agriDaalType"
                value={agriLivestock.agriDaalType}
                checkList={agriDaalType}
                isVisible
                handleCompChange={this.handleChange}
              />
            </Grid>
            <Grid container>
              <TextField
                question={agriDaalIncome}
                name="agriDaalIncome"
                type="number"
                placeholder="दालवाली  खेतीबाट हुने बार्षिक आम्दानि कति हो ? (रु.)"
                value={agriLivestock.daalIncome}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
            <Grid container>
              <TextField
                question={agriDaalExpense}
                name="agriDaalExpense"
                type="number"
                placeholder="दालवाली  खेतीमा हुने बार्षिक लागत खर्च कति हुन्छ? (रु.)"
                value={agriLivestock.daalExpense}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
            <Grid container>
              <TextField
                question={agriDaalArea}
                name="daalRopani"
                type="number"
                placeholder="रोपनी"
                value={agriLivestock.daalRopani}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
            <Grid xs={6}>
              <TextField
                name="daalAana"
                type="number"
                placeholder="आना"
                value={agriLivestock.daalAana}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
            <Grid xs={6}>
              <TextField
                name="daalPaisa"
                type="number"
                placeholder="पैसा"
                value={agriLivestock.daalPaisa}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
          </BoxBorder>
          <BoxBorder
            isVisible={
              agriLivestock.agriDaalType &&
              agriLivestock.agriDaalType.includes("black_daal")
            }
          >
            <Grid container>
              <TextField
                question={agriBlackDaalProductionKg}
                name="agriBlackDaalProductionKg"
                type="number"
                placeholder="के. जि"
                value={agriLivestock.agriBlackDaalProductionKg}
                isVisible
                handleChange={this.handleChange}
              />
              <TextField
                question={agriBlackDaalSaleKg}
                name="agriBlackDaalSaleKg"
                type="number"
                placeholder="के. जि"
                value={agriLivestock.agriBlackDaalSaleKg}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
          </BoxBorder>
          <BoxBorder
            isVisible={
              agriLivestock.agriDaalType &&
              agriLivestock.agriDaalType.includes("simi")
            }
          >
            <Grid container>
              <TextField
                question={agriSimiProductionKg}
                name="agriSimiProductionKg"
                type="number"
                placeholder="के. जि"
                value={agriLivestock.agriSimiProductionKg}
                isVisible
                handleChange={this.handleChange}
              />
              <TextField
                question={agriSimiSaleKg}
                name="agriSimiSaleKg"
                type="number"
                placeholder="के. जि"
                value={agriLivestock.agriSimiSaleKg}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
          </BoxBorder>
          <BoxBorder
            isVisible={
              agriLivestock.agriDaalType &&
              agriLivestock.agriDaalType.includes("musuri")
            }
          >
            <Grid container>
              <TextField
                question={agriMusuriProductionKg}
                name="agriMusuriProductionKg"
                type="number"
                placeholder="के. जि"
                value={agriLivestock.agriMusuriProductionKg}
                isVisible
                handleChange={this.handleChange}
              />
              <TextField
                question={agriMusuriSaleKg}
                name="agriMusuriSaleKg"
                type="number"
                placeholder="के. जि"
                value={agriLivestock.agriMusuriSaleKg}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
          </BoxBorder>
          <BoxBorder
            isVisible={
              agriLivestock.agriDaalType &&
              agriLivestock.agriDaalType.includes("chana")
            }
          >
            <Grid container>
              <TextField
                question={agriChanaProductionKg}
                name="agriChanaProductionKg"
                type="number"
                placeholder="के. जि"
                value={agriLivestock.agriChanaProductionKg}
                isVisible
                handleChange={this.handleChange}
              />
              <TextField
                question={agriChanaSaleKg}
                name="agriChanaSaleKg"
                type="number"
                placeholder="के. जि"
                value={agriLivestock.agriChanaSaleKg}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
          </BoxBorder>
          <BoxBorder
            isVisible={
              agriLivestock.agriDaalType &&
              agriLivestock.agriDaalType.includes("bhatmas")
            }
          >
            <Grid container>
              <TextField
                question={agriBhatmasProductionKg}
                name="agriBhatmasProductionKg"
                type="number"
                placeholder="के. जि"
                value={agriLivestock.agriBhatmasProductionKg}
                isVisible
                handleChange={this.handleChange}
              />
              <TextField
                question={agriBhatmasSaleKg}
                name="agriBhatmasSaleKg"
                type="number"
                placeholder="के. जि"
                value={agriLivestock.agriBhatmasSaleKg}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
          </BoxBorder>
          <BoxBorder
            isVisible={
              agriLivestock.agriProductionType &&
              agriLivestock.agriProductionType.includes("oilseed")
            }
          >
            <Grid container>
              <CheckBox
                question={agriOilTitle}
                name="agriOilType"
                value={agriLivestock.agriOilType}
                checkList={agriOilType}
                isVisible
                handleCompChange={this.handleChange}
              />
            </Grid>
            <Grid container>
              <TextField
                question={agriOilIncome}
                name="agriOilIncome"
                type="number"
                placeholder="तेलहन खेतीबाट हुने बार्षिक आम्दानि कति हो ? (रु.)"
                value={agriLivestock.oilSeedIncome}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
            <Grid container>
              <TextField
                question={agriOilExpense}
                name="agriOilExpense"
                type="number"
                placeholder="तेलहन खेतीमा हुने बार्षिक लागत खर्च कति हुन्छ? (रु.)"
                value={agriLivestock.oilSeedExpense}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
            <Grid container>
              <TextField
                question={agriOilArea}
                name="oilRopani"
                type="number"
                placeholder="रोपनी"
                value={agriLivestock.oilSeedRopani}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
            <Grid xs={6}>
              <TextField
                name="oilAana"
                type="number"
                placeholder="आना"
                value={agriLivestock.oilSeedAana}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
            <Grid xs={6}>
              <TextField
                name="oilPaisa"
                type="number"
                placeholder="पैसा"
                value={agriLivestock.oilPaisa}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
          </BoxBorder>
          <BoxBorder
            isVisible={
              agriLivestock.agriOilType &&
              agriLivestock.agriOilType.includes("tori")
            }
          >
            <Grid container>
              <TextField
                question={agriToriProductionKg}
                name="agriToriProductionKg"
                type="number"
                placeholder="के. जि"
                value={agriLivestock.agriToriProductionKg}
                isVisible
                handleChange={this.handleChange}
              />
              <TextField
                question={agriToriSaleKg}
                name="agriToriSaleKg"
                type="number"
                placeholder="के. जि"
                value={agriLivestock.agriToriSaleKg}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
          </BoxBorder>

          <BoxBorder
            isVisible={
              agriLivestock.agriOilType &&
              agriLivestock.agriOilType.includes("aalash")
            }
          >
            <Grid container>
              <TextField
                question={agriAalashProductionKg}
                name="agriAalashProductionKg"
                type="number"
                placeholder="के. जि"
                value={agriLivestock.agriAalashProductionKg}
                isVisible
                handleChange={this.handleChange}
              />
              <TextField
                question={agriAalashSaleKg}
                name="agriAalashSaleKg"
                type="number"
                placeholder="के. जि"
                value={agriLivestock.agriAalashSaleKg}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
          </BoxBorder>
          <BoxBorder
            isVisible={
              agriLivestock.agriOilType &&
              agriLivestock.agriOilType.includes("filunge")
            }
          >
            <Grid container>
              <TextField
                question={agriFilungeyProductionKg}
                name="agriFilungeyProductionKg"
                type="number"
                placeholder="के. जि"
                value={agriLivestock.agriFilungeyProductionKg}
                isVisible
                handleChange={this.handleChange}
              />
              <TextField
                question={agriFilungeySaleKg}
                name="agriFilungeySaleKg"
                type="number"
                placeholder="के. जि"
                value={agriLivestock.agriFilungeySaleKg}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
          </BoxBorder>

          <BoxBorder
            isVisible={
              agriLivestock.agriOilType &&
              agriLivestock.agriOilType.includes("sunflower")
            }
          >
            <Grid container>
              <TextField
                question={agriSunflowerProductionKg}
                name="agriSunflowerProductionKg"
                type="number"
                placeholder="के. जि"
                value={agriLivestock.agriSunflowerProductionKg}
                isVisible
                handleChange={this.handleChange}
              />
              <TextField
                question={agriSunflowerSaleKg}
                name="agriSunflowerSaleKg"
                type="number"
                placeholder="के. जि"
                value={agriLivestock.agriSunflowerSaleKg}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
          </BoxBorder>
          <BoxBorder
            isVisible={
              agriLivestock.agriProductionType &&
              agriLivestock.agriProductionType.includes("vegetable")
            }
          >
            <Grid container>
              <CheckBox
                question={agriVegetableTitle}
                name="agriVegetableType"
                value={agriLivestock.agriVegetableType}
                checkList={agriVegetableType}
                isVisible
                handleCompChange={this.handleChange}
              />
            </Grid>
            <Grid container>
              <TextField
                question={agriVegetableIncome}
                name="agriVegetableIncome"
                type="number"
                placeholder="तरकारीवाली खेतीबाट हुने बार्षिक आम्दानि कति हो ? (रु.)"
                value={agriLivestock.vegetableIncome}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
            <Grid container>
              <TextField
                question={agriVegetableExpense}
                name="agriVegetableExpense"
                type="number"
                placeholder="तरकारीवाली खेतीमा हुने बार्षिक लागत खर्च कति हुन्छ? (रु.)"
                value={agriLivestock.vegetableExpense}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
            <Grid container>
              <TextField
                question={agriVegetableArea}
                name="vegetableRopani"
                type="number"
                placeholder="रोपनी"
                value={agriLivestock.vegetableRopani}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
            <Grid xs={6}>
              <TextField
                name="vegetableAana"
                type="number"
                placeholder="आना"
                value={agriLivestock.vegetableAana}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
            <Grid xs={6}>
              <TextField
                name="vegetablePaisa"
                type="number"
                placeholder="पैसा"
                value={agriLivestock.vegetablePaisa}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
          </BoxBorder>
          <BoxBorder
            isVisible={
              agriLivestock.agriVegetableType &&
              agriLivestock.agriVegetableType.includes("potato")
            }
          >
            <Grid container>
              <TextField
                question={agriPotatoProductionKg}
                name="agriPotatoProductionKg"
                type="number"
                placeholder="के. जि"
                value={agriLivestock.agriPotatoProductionKg}
                isVisible
                handleChange={this.handleChange}
              />
              <TextField
                question={agriPotatoSaleKg}
                name="agriPotatoSaleKg"
                type="number"
                placeholder="के. जि"
                value={agriLivestock.agriPotatoSaleKg}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
          </BoxBorder>
          <BoxBorder
            isVisible={
              agriLivestock.agriVegetableType &&
              agriLivestock.agriVegetableType.includes("cabbage")
            }
          >
            <Grid container>
              <TextField
                question={agriCabbageProductionKg}
                name="agriCabbageProductionKg"
                type="number"
                placeholder="के. जि"
                value={agriLivestock.agriCabbageProductionKg}
                isVisible
                handleChange={this.handleChange}
              />
              <TextField
                question={agriCabbageSaleKg}
                name="agriCabbageSaleKg"
                type="number"
                placeholder="के. जि"
                value={agriLivestock.agriCabbageSaleKg}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
          </BoxBorder>

          <BoxBorder
            isVisible={
              agriLivestock.agriVegetableType &&
              agriLivestock.agriVegetableType.includes("tomato")
            }
          >
            <Grid container>
              <TextField
                question={agriTomatoProductionKg}
                name="agriTomatoProductionKg"
                type="number"
                placeholder="के. जि"
                value={agriLivestock.agriTomatoProductionKg}
                isVisible
                handleChange={this.handleChange}
              />
              <TextField
                question={agriTomatoSaleKg}
                name="agriTomatoSaleKg"
                type="number"
                placeholder="के. जि"
                value={agriLivestock.agriTomatoSaleKg}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
          </BoxBorder>
          <BoxBorder
            isVisible={
              agriLivestock.agriVegetableType &&
              agriLivestock.agriVegetableType.includes("cucumber")
            }
          >
            <Grid container>
              <TextField
                question={agriCucumberProductionKg}
                name="agriCucumberProductionKg"
                type="number"
                placeholder="के. जि"
                value={agriLivestock.agriCucumberProductionKg}
                isVisible
                handleChange={this.handleChange}
              />
              <TextField
                question={agriCucumberSaleKg}
                name="agriCucumberSaleKg"
                type="number"
                placeholder="के. जि"
                value={agriLivestock.agriCucumberSaleKg}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
          </BoxBorder>
          <BoxBorder
            isVisible={
              agriLivestock.agriVegetableType &&
              agriLivestock.agriVegetableType.includes("bittergroud")
            }
          >
            <Grid container>
              <TextField
                question={agriGhiraulaProductionKg}
                name="agriGhiraulaProductionKg"
                type="number"
                placeholder="के. जि"
                value={agriLivestock.agriGhiraulaProductionKg}
                isVisible
                handleChange={this.handleChange}
              />
              <TextField
                question={agriGhiraulaSaleKg}
                name="agriGhiraulaSaleKg"
                type="number"
                placeholder="के. जि"
                value={agriLivestock.agriGhiraulaSaleKg}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
          </BoxBorder>

          <BoxBorder
            isVisible={
              agriLivestock.agriVegetableType &&
              agriLivestock.agriVegetableType.includes("saag")
            }
          >
            <Grid container>
              <TextField
                question={agriSaagProductionKg}
                name="agriSaagProductionKg"
                type="number"
                placeholder="के. जि"
                value={agriLivestock.agriSaagProductionKg}
                isVisible
                handleChange={this.handleChange}
              />
              <TextField
                question={agriSaagSaleKg}
                name="agriSaagSaleKg"
                type="number"
                placeholder="के. जि"
                value={agriLivestock.agriSaagSaleKg}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
          </BoxBorder>
          <BoxBorder
            isVisible={
              agriLivestock.agriProductionType &&
              agriLivestock.agriProductionType.includes("fruit")
            }
          >
            <Grid container>
              <CheckBox
                question={agriFruitTitle}
                name="agriFruitType"
                value={agriLivestock.agriFruitType}
                checkList={agriFruitType}
                isVisible
                handleCompChange={this.handleChange}
              />
            </Grid>
            <Grid container>
              <TextField
                question={agriFruitIncome}
                name="agriFruitIncome"
                type="number"
                placeholder="फलफुलखेती खेतीबाट हुने बार्षिक आम्दानि कति हो ? (रु.)"
                value={agriLivestock.fruitIncome}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
            <Grid container>
              <TextField
                question={agriFruitExpense}
                name="agriFruitExpense"
                type="number"
                placeholder="फलफुलखेती खेतीमा हुने बार्षिक लागत खर्च कति हुन्छ? (रु.)"
                value={agriLivestock.fruitExpense}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
            <Grid container>
              <TextField
                question={agriFruitArea}
                name="fruitRopani"
                type="number"
                placeholder="रोपनी"
                value={agriLivestock.fruitRopani}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
            <Grid xs={6}>
              <TextField
                name="fruitAana"
                type="number"
                placeholder="आना"
                value={agriLivestock.fruitAana}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
            <Grid xs={6}>
              <TextField
                name="fruitPaisa"
                type="number"
                placeholder="पैसा"
                value={agriLivestock.fruitPaisa}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
          </BoxBorder>
          <BoxBorder
            isVisible={
              agriLivestock.agriFruitType &&
              agriLivestock.agriFruitType.includes("mango")
            }
          >
            <Grid container>
              <TextField
                question={agriMangoProductionKg}
                name="agriMangoProductionKg"
                type="number"
                placeholder="के. जि"
                value={agriLivestock.agriMangoProductionKg}
                isVisible
                handleChange={this.handleChange}
              />
              <TextField
                question={agriMangoSaleKg}
                name="agriMangoSaleKg"
                type="number"
                placeholder="के. जि"
                value={agriLivestock.agriMangoSaleKg}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
          </BoxBorder>

          <BoxBorder
            isVisible={
              agriLivestock.agriFruitType &&
              agriLivestock.agriFruitType.includes("banana")
            }
          >
            <Grid container>
              <TextField
                question={agriBananaProductionKg}
                name="agriBananaProductionKg"
                type="number"
                placeholder="के. जि"
                value={agriLivestock.agriBananaProductionKg}
                isVisible
                handleChange={this.handleChange}
              />
              <TextField
                question={agriBananaSaleKg}
                name="agriBananaSaleKg"
                type="number"
                placeholder="के. जि"
                value={agriLivestock.agriBananaSaleKg}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
          </BoxBorder>
          <BoxBorder
            isVisible={
              agriLivestock.agriFruitType &&
              agriLivestock.agriFruitType.includes("orange")
            }
          >
            <Grid container>
              <TextField
                question={agriOrangeProductionKg}
                name="agriOrangeProductionKg"
                type="number"
                placeholder="के. जि"
                value={agriLivestock.agriOrangeProductionKg}
                isVisible
                handleChange={this.handleChange}
              />
              <TextField
                question={agriOrangeSaleKg}
                name="agriOrangeSaleKg"
                type="number"
                placeholder="के. जि"
                value={agriLivestock.agriOrangeSaleKg}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
          </BoxBorder>

          <BoxBorder
            isVisible={
              agriLivestock.agriFruitType &&
              agriLivestock.agriFruitType.includes("apple")
            }
          >
            <Grid container>
              <TextField
                question={agriAppleProductionKg}
                name="agriAppleProductionKg"
                type="number"
                placeholder="के. जि"
                value={agriLivestock.agriAppleProductionKg}
                isVisible
                handleChange={this.handleChange}
              />
              <TextField
                question={agriAppleSaleKg}
                name="agriAppleSaleKg"
                type="number"
                placeholder="के. जि"
                value={agriLivestock.agriAppleSaleKg}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
          </BoxBorder>
          <BoxBorder
            isVisible={
              agriLivestock.agriFruitType &&
              agriLivestock.agriFruitType.includes("pulam")
            }
          >
            <Grid container>
              <TextField
                question={agriKiwiProductionKg}
                name="agriKiwiProductionKg"
                type="number"
                placeholder="के. जि"
                value={agriLivestock.agriKiwiProductionKg}
                isVisible
                handleChange={this.handleChange}
              />
              <TextField
                question={agriKiwiSaleKg}
                name="agriKiwiSaleKg"
                type="number"
                placeholder="के. जि"
                value={agriLivestock.agriKiwiSaleKg}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
          </BoxBorder>

          <BoxBorder
            isVisible={
              agriLivestock.agriFruitType &&
              agriLivestock.agriFruitType.includes("avogado")
            }
          >
            <Grid container>
              <TextField
                question={agriAvogadoProductionKg}
                name="agriAvogadoProductionKg"
                type="number"
                placeholder="के. जि"
                value={agriLivestock.agriAvogadoProductionKg}
                isVisible
                handleChange={this.handleChange}
              />
              <TextField
                question={agriAvogadoSaleKg}
                name="agriAvogadoSaleKg"
                type="number"
                placeholder="के. जि"
                value={agriLivestock.agriAvogadoSaleKg}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
          </BoxBorder>
          <BoxBorder
            isVisible={
              agriLivestock.agriProductionType &&
              agriLivestock.agriProductionType.includes("cash_crops")
            }
          >
            <Grid container>
              <CheckBox
                question={cashCropTitle}
                name="cashCropType"
                value={agriLivestock.cashCropType}
                checkList={cashCropType}
                isVisible
                handleCompChange={this.handleChange}
              />
            </Grid>
            <Grid container>
              <TextField
                question={agriCashCropIncome}
                name="agriCashCropIncome"
                type="number"
                placeholder="नगदेबाली खेतीबाट हुने बार्षिक आम्दानि कति हो ? (रु.)"
                value={agriLivestock.cashCropIncome}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
            <Grid container>
              <TextField
                question={agriCashCropExpense}
                name="agriCashCropExpense"
                type="number"
                placeholder="नगदेबाली खेतीमा हुने बार्षिक लागत खर्च कति हुन्छ? (रु.)"
                value={agriLivestock.cashCropExpense}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
            <Grid container>
              <TextField
                question={agriCashCropArea}
                name="cashCropRopani"
                type="number"
                placeholder="रोपनी"
                value={agriLivestock.cashCropRopani}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
            <Grid xs={6}>
              <TextField
                name="cashCropAana"
                type="number"
                placeholder="आना"
                value={agriLivestock.cashCropAana}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
            <Grid xs={6}>
              <TextField
                name="cashCropPaisa"
                type="number"
                placeholder="पैसा"
                value={agriLivestock.cashCropPaisa}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
          </BoxBorder>

          <BoxBorder
            isVisible={
              agriLivestock.cashCropType &&
              agriLivestock.cashCropType.includes("alaichi")
            }
          >
            <Grid container>
              <TextField
                question={agriAlaichiProductionKg}
                name="agriAlaichiProductionKg"
                type="number"
                placeholder="के. जि"
                value={agriLivestock.agriAlaichiProductionKg}
                isVisible
                handleChange={this.handleChange}
              />
              <TextField
                question={agriAlaichiSaleKg}
                name="agriAlaichiSaleKg"
                type="number"
                placeholder="के. जि"
                value={agriLivestock.agriAlaichiSaleKg}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
          </BoxBorder>

          <BoxBorder
            isVisible={
              agriLivestock.cashCropType &&
              agriLivestock.cashCropType.includes("rudraksh")
            }
          >
            <Grid container>
              <TextField
                question={agriRudrakshProductionKg}
                name="agriRudrakshProductionKg"
                type="number"
                placeholder="के. जि"
                value={agriLivestock.agriRudrakshProductionKg}
                isVisible
                handleChange={this.handleChange}
              />
              <TextField
                question={agriRudrakshSaleKg}
                name="agriRudrakshSaleKg"
                type="number"
                placeholder="के. जि"
                value={agriLivestock.agriRudrakshSaleKg}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
          </BoxBorder>

          <BoxBorder
            isVisible={
              agriLivestock.cashCropType &&
              agriLivestock.cashCropType.includes("amriso")
            }
          >
            <Grid container>
              <TextField
                question={agriAmrisoProductionKg}
                name="agriAmrisoProductionKg"
                type="number"
                placeholder="के. जि"
                value={agriLivestock.agriAmrisoProductionKg}
                isVisible
                handleChange={this.handleChange}
              />
              <TextField
                question={agriAmrisoSaleKg}
                name="agriAmrisoSaleKg"
                type="number"
                placeholder="के. जि"
                value={agriLivestock.agriAmrisoSaleKg}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
          </BoxBorder>
          <BoxBorder
            isVisible={
              agriLivestock.cashCropType &&
              agriLivestock.cashCropType.includes("coffee")
            }
          >
            <Grid container>
              <TextField
                question={agriCoffeeProductionKg}
                name="agriCoffeeProductionKg"
                type="number"
                placeholder="के. जि"
                value={agriLivestock.agriCoffeeProductionKg}
                isVisible
                handleChange={this.handleChange}
              />
              <TextField
                question={agriCoffeeSaleKg}
                name="agriCoffeeSaleKg"
                type="number"
                placeholder="के. जि"
                value={agriLivestock.agriCoffeeSaleKg}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
          </BoxBorder>
          <BoxBorder
            isVisible={
              agriLivestock.cashCropType &&
              agriLivestock.cashCropType.includes("tea")
            }
          >
            <Grid container>
              <TextField
                question={agriTeaProductionKg}
                name="agriTeaProductionKg"
                type="number"
                placeholder="के. जि"
                value={agriLivestock.agriTeaProductionKg}
                isVisible
                handleChange={this.handleChange}
              />
              <TextField
                question={agriTeaSaleKg}
                name="agriTeaSaleKg"
                type="number"
                placeholder="के. जि"
                value={agriLivestock.agriTeaSaleKg}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
          </BoxBorder>

          <BoxBorder
            isVisible={
              agriLivestock.cashCropType &&
              agriLivestock.cashCropType.includes("ginger")
            }
          >
            <Grid container>
              <TextField
                question={agriGingerProductionKg}
                name="agriGingerProductionKg"
                type="number"
                placeholder="के. जि"
                value={agriLivestock.agriGingerProductionKg}
                isVisible
                handleChange={this.handleChange}
              />
              <TextField
                question={agriGingerSaleKg}
                name="agriGingerSaleKg"
                type="number"
                placeholder="के. जि"
                value={agriLivestock.agriGingerSaleKg}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
          </BoxBorder>

          <BoxBorder
            isVisible={
              agriLivestock.cashCropType &&
              agriLivestock.cashCropType.includes("garlic")
            }
          >
            <Grid container>
              <TextField
                question={agriGarlicProductionKg}
                name="agriGarlicProductionKg"
                type="number"
                placeholder="के. जि"
                value={agriLivestock.agriGarlicProductionKg}
                isVisible
                handleChange={this.handleChange}
              />
              <TextField
                question={agriGarlicSaleKg}
                name="agriGarlicSaleKg"
                type="number"
                placeholder="के. जि"
                value={agriLivestock.agriGarlicSaleKg}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
          </BoxBorder>

          <BoxBorder
            isVisible={
              agriLivestock.cashCropType &&
              agriLivestock.cashCropType.includes("mushroom")
            }
          >
            <Grid container>
              <TextField
                question={agriMushroomProductionKg}
                name="agriMushroomProductionKg"
                type="number"
                placeholder="के. जि"
                value={agriLivestock.agriMushroomProductionKg}
                isVisible
                handleChange={this.handleChange}
              />
              <TextField
                question={agriMushroomSaleKg}
                name="agriMushroomSaleKg"
                type="number"
                placeholder="के. जि"
                value={agriLivestock.agriMushroomSaleKg}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
          </BoxBorder>
          <BoxBorder
            isVisible={
              agriLivestock.cashCropType &&
              agriLivestock.cashCropType.includes("sugarcane")
            }
          >
            <Grid container>
              <TextField
                question={agriSugarcaneProductionKg}
                name="agriSugarcaneProductionKg"
                type="number"
                placeholder="के. जि"
                value={agriLivestock.agriSugarcaneProductionKg}
                isVisible
                handleChange={this.handleChange}
              />
              <TextField
                question={agriSugarcaneSaleKg}
                name="agriSugarcaneSaleKg"
                type="number"
                placeholder="के. जि"
                value={agriLivestock.agriSugarcaneSaleKg}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
          </BoxBorder>
          <BoxBorder
            isVisible={
              agriLivestock.cashCropType &&
              agriLivestock.cashCropType.includes("nut")
            }
          >
            <Grid container>
              <TextField
                question={agriNutProductionKg}
                name="agriNutProductionKg"
                type="number"
                placeholder="के. जि"
                value={agriLivestock.agriNutProductionKg}
                isVisible
                handleChange={this.handleChange}
              />
              <TextField
                question={agriNutSaleKg}
                name="agriNutSaleKg"
                type="number"
                placeholder="के. जि"
                value={agriLivestock.agriNutSaleKg}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
          </BoxBorder>
          <BoxBorder
            isVisible={
              agriLivestock.cashCropType &&
              agriLivestock.cashCropType.includes("chilly")
            }
          >
            <Grid container>
              <TextField
                question={agriChillyProductionKg}
                name="agriChillyProductionKg"
                type="number"
                placeholder="के. जि"
                value={agriLivestock.agriChillyProductionKg}
                isVisible
                handleChange={this.handleChange}
              />
              <TextField
                question={agriChillySaleKg}
                name="agriChillySaleKg"
                type="number"
                placeholder="के. जि"
                value={agriLivestock.agriChillySaleKg}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
          </BoxBorder>

          <BoxBorder
            isVisible={
              agriLivestock.agriProductionType &&
              agriLivestock.agriProductionType.includes("flower")
            }
          >
            <Grid container>
              <TextField
                question={flowerProduction}
                name="flowerProduction"
                type="number"
                placeholder="फुलखेतीको उत्पादन"
                value={agriLivestock.flowerProduction}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
            <Grid container>
              <TextField
                question={flowerSale}
                name="flowerSale"
                type="number"
                placeholder="विक्री परिमाण (के.जी. मा)"
                value={agriLivestock.flowerSale}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
            <Grid container>
              <TextField
                question={flowerSaleQuantity}
                name="flowerSaleQuantity"
                type="number"
                placeholder="फुलखेती उत्पादन वाट बार्षिक आम्दानी कति हो ?"
                value={agriLivestock.flowerSaleQuantity}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
          </BoxBorder>
          <Grid container>
            <RadioButton
              question={insecticideQuestion}
              name="insecticideYesNo"
              value={agriLivestock.insecticideYesNo}
              radioLists={insecticideYesNo}
              isVisible
              handleChange={this.handleChange}
            />
          </Grid>

          <BoxBorder
            isVisible={
              agriLivestock.insecticideYesNo &&
              agriLivestock.insecticideYesNo.includes("chemical_yes")
            }
          >
            <Grid container>
              <RadioButton
                question={seedNeedQuestion}
                name="seedNeed"
                value={agriLivestock.seedNeed}
                radioLists={seedNeed}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
            <Grid container>
              <RadioButton
                question={insecticideYes}
                name="insecticideType"
                value={agriLivestock.insecticideType}
                radioLists={insecticideType}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
          </BoxBorder>
        </BoxBorder>
        <Grid container>
          <RadioButton
            question={livestockQuestion}
            handleChange={this.handleChange}
            isVisible
            name="livestockYesNo"
            radioLists={livestockYesNo}
            value={agriLivestock.livestockYesNo}
          />
        </Grid>

        <BoxBorder
          isVisible={
            agriLivestock.livestockYesNo &&
            agriLivestock.livestockYesNo.includes("yes")
          }
        >
          <Grid container>
            <CheckBox
              question={livestockYes}
              name="livestockType"
              value={agriLivestock.livestockType}
              checkList={livestockType}
              isVisible
              handleCompChange={this.handleChange}
            />
          </Grid>

          <BoxBorder
            isVisible={
              agriLivestock.livestockType &&
              agriLivestock.livestockType.includes("animal")
            }
          >
            <Grid container>
              <CheckBox
                question={livestockTitle}
                name="livestockDiffType"
                value={agriLivestock.livestockDiffType}
                checkList={livestockDiffType}
                isVisible
                handleCompChange={this.handleChange}
              />
            </Grid>
          </BoxBorder>
          <BoxBorder
            isVisible={
              agriLivestock.livestockDiffType &&
              agriLivestock.livestockDiffType.includes("cow")
            }
          >
            <Grid container>
              <TextField
                question={cowNumber}
                name="cowNumber"
                type="number"
                placeholder="गाईको संख्या"
                value={agriLivestock.cowNumber}
                isVisible
                handleChange={this.handleChange}
              />
              <TextField
                question={cowMilkProduction}
                name="cowMilkProduction"
                type="number"
                placeholder="गाईको दैनिक दुध उत्पादन (लिटरमा )"
                value={agriLivestock.cowMilkProduction}
                isVisible
                handleChange={this.handleChange}
              />
              <TextField
                question={cowMilkProductionSale}
                name="cowMilkProductionSale"
                type="number"
                placeholder="दुध बिक्रि परिमाण कति होला ? (औसत बार्षिक )"
                value={agriLivestock.cowMilkProductionSale}
                isVisible
                handleChange={this.handleChange}
              />
              <TextField
                question={cowMilkSale}
                name="cowMilkSale"
                type="number"
                placeholder="दुध बिक्रिबाट हुने बार्षिक आम्दानी (रु हजारमा )"
                value={agriLivestock.cowMilkSale}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
          </BoxBorder>
          <BoxBorder
            isVisible={
              agriLivestock.livestockDiffType &&
              agriLivestock.livestockDiffType.includes("buffallo")
            }
          >
            <Grid container>
              <TextField
                question={buffalloNumber}
                name="buffalloNumber"
                type="number"
                placeholder="भैसीको संख्या"
                value={agriLivestock.buffalloNumber}
                isVisible
                handleChange={this.handleChange}
              />
              <TextField
                question={buffalloMilkProduction}
                name="buffalloMilkProduction"
                type="number"
                placeholder="भैसीको दैनिक दुध उत्पादन (लिटरमा )"
                value={agriLivestock.buffalloMilkProduction}
                isVisible
                handleChange={this.handleChange}
              />
              <TextField
                question={buffalloMilkSale}
                name="buffalloMilkSale"
                type="number"
                placeholder="दुध बिक्रिबाट हुने बार्षिक आम्दानी (रु हजारमा )"
                value={agriLivestock.buffalloMilkSale}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
          </BoxBorder>
          <BoxBorder
            isVisible={
              agriLivestock.livestockDiffType &&
              agriLivestock.livestockDiffType.includes("goat/boka")
            }
          >
            <Grid container>
              <TextField
                question={khasiNumber}
                name="khasiNumber"
                type="number"
                placeholder="खसी/बोकाक संख्या"
                value={agriLivestock.khasiNumber}
                isVisible
                handleChange={this.handleChange}
              />
              <TextField
                question={khasiMeatProduction}
                name="khasiMeatProduction"
                type="number"
                placeholder="खसी/बोका मासुबिक्रि (के जिमा )"
                value={agriLivestock.khasiMeatProduction}
                isVisible
                handleChange={this.handleChange}
              />
              <TextField
                question={khasiMeatIncome}
                name="khasiMeatIncome"
                type="number"
                placeholder="मासु वा खसी/बोका बिक्रिबाट बार्षिक आम्दानी (रु हजारमा )"
                value={agriLivestock.khasiMeatIncome}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
          </BoxBorder>

          <BoxBorder
            isVisible={
              agriLivestock.livestockDiffType &&
              agriLivestock.livestockDiffType.includes("ranga")
            }
          >
            <Grid container>
              <TextField
                question={rangaNumber}
                name="rangaNumber"
                type="number"
                placeholder="राँगा/पाडा/पाडी संख्या"
                value={agriLivestock.rangaNumber}
                isVisible
                handleChange={this.handleChange}
              />
              <TextField
                question={rangaMeatProduction}
                name="rangaMeatProduction"
                type="number"
                placeholder="मासु छाला वा पशुबाट बार्षिक बिक्रि (के .जीमा )"
                value={agriLivestock.rangaMeatProduction}
                isVisible
                handleChange={this.handleChange}
              />
              <TextField
                question={rangaMeatIncome}
                name="rangaMeatIncome"
                type="number"
                placeholder="मासु वा पशु बिक्रिबाट बार्षिक आम्दानी (रु हजार )"
                value={agriLivestock.rangaMeatIncome}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
          </BoxBorder>
          <BoxBorder
            isVisible={
              agriLivestock.livestockDiffType &&
              agriLivestock.livestockDiffType.includes("pig")
            }
          >
            <Grid container>
              <TextField
                question={pigNumber}
                name="pigNumber"
                type="number"
                placeholder="सुँगुर/बंगुरको संख्या"
                value={agriLivestock.pigNumber}
                isVisible
                handleChange={this.handleChange}
              />
              <TextField
                question={pigMeatProduction}
                name="pigMeatProduction"
                type="number"
                placeholder="मासु बिक्रि बार्षिक (के .जीमा )"
                value={agriLivestock.pigMeatProduction}
                isVisible
                handleChange={this.handleChange}
              />
              <TextField
                question={pigMeatIncome}
                name="pigMeatIncome"
                type="number"
                placeholder="मासु वा पशु बिक्रिबाट बार्षिक आम्दानी (रु हजारमा)"
                value={agriLivestock.pigMeatIncome}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
          </BoxBorder>
          <BoxBorder
            isVisible={
              agriLivestock.livestockDiffType &&
              agriLivestock.livestockDiffType.includes("sheep")
            }
          >
            <Grid container>
              <TextField
                question={sheepNumber}
                name="sheepNumber"
                type="number"
                placeholder="भेडाको संख्या"
                value={agriLivestock.sheepNumber}
                isVisible
                handleChange={this.handleChange}
              />
              <TextField
                question={sheepMeatProduction}
                name="sheepMeatProduction"
                type="number"
                placeholder="भेडाको मासु बिक्रि दैनिक (के जीमा )"
                value={agriLivestock.sheepMeatProduction}
                isVisible
                handleChange={this.handleChange}
              />
              <TextField
                question={sheepMeatIncome}
                name="sheepMeatIncome"
                type="number"
                placeholder="भेडाको उन उत्पादन तथा मासु बिक्रि बार्षिकरुपमा (रु हजारमा )"
                value={agriLivestock.sheepMeatIncome}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
          </BoxBorder>

          <BoxBorder
            isVisible={
              agriLivestock.livestockDiffType &&
              agriLivestock.livestockDiffType.includes("garu")
            }
          >
            <Grid container>
              <TextField
                question={oxNumber}
                name="oxNumber"
                type="number"
                placeholder="गोरुको संख्या"
                value={agriLivestock.oxNumber}
                isVisible
                handleChange={this.handleChange}
              />

              <TextField
                question={oxMeatIncome}
                name="oxMeatIncome"
                type="number"
                placeholder="पशुबिक्रिबाट बार्षिक आम्दानी (रु हजारमा )"
                value={agriLivestock.oxMeatIncome}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
          </BoxBorder>
          <BoxBorder
            isVisible={
              agriLivestock.livestockDiffType &&
              agriLivestock.livestockDiffType.includes("gadha")
            }
          >
            <Grid container>
              <TextField
                question={gadhaNumber}
                name="gadhaNumber"
                type="number"
                placeholder="गधाको संख्या"
                value={agriLivestock.gadhaNumber}
                isVisible
                handleChange={this.handleChange}
              />
              <TextField
                question={gadhaProduction}
                name="gadhaProduction"
                type="number"
                placeholder="मासु बिक्रि बार्षिक (के .जीमा )"
                value={agriLivestock.gadhaProduction}
                isVisible
                handleChange={this.handleChange}
              />
              <TextField
                question={gadhaMeatIncome}
                name="gadhaMeatIncome"
                type="number"
                placeholder="मासु वा पशु बिक्रिबाट बार्षिक आम्दानी (रु हजारमा)"
                value={agriLivestock.gadhaMeatIncome}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
          </BoxBorder>

          <BoxBorder
            isVisible={
              agriLivestock.livestockDiffType &&
              agriLivestock.livestockDiffType.includes("yak")
            }
          >
            <Grid container>
              <TextField
                question={yakNumber}
                name="yakNumber"
                type="number"
                placeholder="याकको संख्या"
                value={agriLivestock.yakNumber}
                isVisible
                handleChange={this.handleChange}
              />
              <TextField
                question={yakMilkProduction}
                name="yakMilkProduction"
                type="number"
                placeholder="दुध उत्पादन दैनिकरुपमा (लिटरमा )"
                value={agriLivestock.yakMilkProduction}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
            <Grid container>
              <TextField
                question={yakProduction}
                name="yakProduction"
                type="number"
                placeholder="छुर्पी उत्पादन गर्छ भने बार्षिक रुपमा कति केजी ?"
                value={agriLivestock.yakProduction}
                isVisible
                handleChange={this.handleChange}
              />
              <TextField
                question={yakMeatIncome}
                name="yakMeatIncome"
                type="number"
                placeholder="याकको दुध वा छुर्पीबाट बार्षिक रुपमा कति आम्दानी हुन्छ? ( रु हजारमा )"
                value={agriLivestock.yakMeatIncome}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
          </BoxBorder>

          <BoxBorder
            isVisible={
              agriLivestock.livestockType &&
              agriLivestock.livestockType.includes("bird")
            }
          >
            <Grid container>
              <CheckBox
                question={birdTitle}
                name="birdDiffType"
                value={agriLivestock.birdDiffType}
                checkList={birdDiffType}
                isVisible
                handleCompChange={this.handleChange}
              />
            </Grid>
          </BoxBorder>
          <BoxBorder
            isVisible={
              agriLivestock.birdDiffType &&
              agriLivestock.birdDiffType.includes("chicken")
            }
          >
            <Grid container>
              <TextField
                question={henNumber}
                name="henNumber"
                type="number"
                placeholder="कुखुराको संख्या (कुखुरा फारम भए फारममा कति संख्या कुखुरा छ उल्लेख गर्नुस )"
                value={agriLivestock.henNumber}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
            <Grid container>
              <TextField
                question={henMeatProduction}
                name="henMeatProduction"
                type="number"
                placeholder="कुखुराको मासुबिक्रि दैनिक रुपमा (के जीमा )"
                value={agriLivestock.henMeatProduction}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
            <Grid container>
              <TextField
                question={henEggSale}
                name="henEggSale"
                type="number"
                placeholder="कुखुराको अन्डा बिक्रि दैनिक रुपमा (गोटा )"
                value={agriLivestock.henEggSale}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
            <Grid container>
              <TextField
                question={henMeatIncome}
                name="henMeatIncome"
                type="number"
                placeholder="कुखुराको मासु,अन्डा वा कुखुरा बिक्रीबाट बार्षिक रुपमा आम्दानी (रु हजारमा)"
                value={agriLivestock.henMeatIncome}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
          </BoxBorder>

          <BoxBorder
            isVisible={
              agriLivestock.birdDiffType &&
              agriLivestock.birdDiffType.includes("hybrid")
            }
          >
            <Grid container>
              <TextField
                question={hybridNumber}
                name="hybridNumber"
                type="number"
                placeholder="हाइब्रिड कुखुरा संख्या"
                value={agriLivestock.hybridNumber}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
            <Grid container>
              <TextField
                question={hybridMeatProduction}
                name="hybridMeatProduction"
                type="number"
                placeholder="हाइब्रिड कुखुरा मासु बिक्रि बार्षिक रुपमा"
                value={agriLivestock.hybridMeatProduction}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
            <Grid container>
              <TextField
                question={hybridEggSale}
                name="hybridEggSale"
                type="number"
                placeholder="हाइब्रिड खुराको अन्डा उत्पादन बार्षिक"
                value={agriLivestock.hybridEggSale}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
          </BoxBorder>
          <BoxBorder
            isVisible={
              agriLivestock.birdDiffType &&
              agriLivestock.birdDiffType.includes("pegion")
            }
          >
            <Grid container>
              <TextField
                question={duckNumber}
                name="duckNumber"
                type="number"
                placeholder="हाँसको संख्या"
                value={agriLivestock.duckNumber}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
            <Grid container>
              <TextField
                question={duckMeatProduction}
                name="duckMeatProduction"
                type="number"
                placeholder="हाँसको अन्डा बार्षिक उत्पादन"
                value={agriLivestock.duckMeatProduction}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
          </BoxBorder>
        </BoxBorder>
        <Grid container>
          <RadioButton
            question={beefishsilkQuestion}
            handleChange={this.handleChange}
            isVisible
            name="beefishsilkYesNo"
            radioLists={beefishsilkYesNo}
            value={agriLivestock.beefishsilkYesNo}
          />
        </Grid>
        <BoxBorder
          isVisible={
            agriLivestock.beefishsilkYesNo &&
            agriLivestock.beefishsilkYesNo.includes("yes")
          }
        >
          <Grid container>
            <CheckBox
              question={beefishsilkYes}
              name="beefishsilkType"
              value={agriLivestock.beefishsilkType}
              checkList={beefishsilkType}
              isVisible
              handleCompChange={this.handleChange}
            />
          </Grid>

          <BoxBorder
            isVisible={
              agriLivestock.beefishsilkType &&
              agriLivestock.beefishsilkType.includes("fish")
            }
          >
            <Grid container>
              <TextField
                question={fishTitle}
                name="totalPond"
                type="number"
                placeholder="पोखरि संख्या"
                value={agriLivestock.totalPond}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
            <Grid container>
              <TextField
                question={pondArea}
                name="pondArea"
                type="number"
                placeholder="पोखरी क्षेत्रफ़ल"
                value={agriLivestock.pondArea}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
            <Grid container>
              <TextField
                question={fishProduction}
                name="fishProduction"
                type="number"
                placeholder="बार्षिक माछा उत्पादन (के.जी)"
                value={agriLivestock.fishProduction}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
          </BoxBorder>
          <BoxBorder
            isVisible={
              agriLivestock.beefishsilkType &&
              agriLivestock.beefishsilkType.includes("bee")
            }
          >
            <Grid container>
              <TextField
                question={beeTitle}
                name="totalGhaar"
                type="number"
                placeholder="घार संख्या"
                value={agriLivestock.totalGhaar}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
            <Grid container>
              <TextField
                question={beeProduction}
                name="beeProduction"
                type="number"
                placeholder="वार्षिक मह उत्पादन(के.जी)"
                value={agriLivestock.beeProduction}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
          </BoxBorder>
          <BoxBorder
            isVisible={
              agriLivestock.beefishsilkType &&
              agriLivestock.beefishsilkType.includes("resham")
            }
          >
            <Grid container>
              <TextField
                question={reshamTitle}
                name="reshamGhaar"
                type="number"
                placeholder="कोयो संख्या"
                value={agriLivestock.reshamGhaar}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
            <Grid container>
              <TextField
                question={reshamProduction}
                name="reshamProduction"
                type="number"
                placeholder="उत्पादन(के.जी)"
                value={agriLivestock.reshamProduction}
                isVisible
                handleChange={this.handleChange}
              />
            </Grid>
          </BoxBorder>
          <Grid xs={6}>
            <TextField
              question={totalBeeFishSilkIncome}
              name="agriSilkSaleQuantity"
              type="number"
              placeholder="माछा मौरी रेशम पालन बिक्रीबाट वार्षिक आम्दानी (रु)"
              value={agriLivestock.agriSilkSaleQuantity}
              isVisible={agriLivestock.beefishsilkYesNo == "yes"}
              handleChange={this.handleChange}
            />
          </Grid>
        </BoxBorder>
      </Grid>
    );
  }
}

const styles = theme => ({
  root: {
    display: "flex"
  },
  formControl: {
    margin: theme.spacing.unit * 3
  },
  group: {
    margin: `${theme.spacing.unit}px 0`,
    display: "flex"
  },
  chips: {
    display: "flex",
    flexWrap: "wrap"
  },
  chip: {
    margin: theme.spacing.unit / 4
  },
  noLabel: {
    marginTop: theme.spacing.unit * 3
  },
  flexColumn: {
    flexDirection: "column"
  }
});

AgriLivestock.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(AgriLivestock);
