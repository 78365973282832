import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { selectedLanguage, yes, no } from "../../../../../variable/global";
import {
  QuestionAnswer,
  Title
} from "../../../../common/frequent/QuestionAnswer";
import getNepalNumber from "../../../../../utils/getNepaliNumber";
import {
  economic,
  mainincome,
  yearlyIncome,
  yearlyExpenditure,
  loanGiven,
  loanGivenInterest,
  loanTaken,
  loanType,
  loanInterest,
  foodForMonthQuestion,
  foodAlternateQuestion,
  loanTakingReasonQuestion
} from "../../../../../variable/houseSurvey";

function mapValue(value, obj) {
  return obj[value];
}

class SearchByHouseNumber extends Component {
  getYesNo = data => (data ? yes[selectedLanguage] : no[selectedLanguage]);
  multimapValue = (name, object) =>
    name && name.map(each => object[each]).join(",");
  render() {
    const { classes, economics } = this.props;
    if (economics === undefined) return;
    return (
      <Grid item container direction="column" className={classes.container}>
        <Title title={economic} />
        <Grid item container direction="row">
          <Grid item container xs={12}>
            {/* <Grid item container direction="row" className={classes.eachQnAns}>
              <Grid item xs={12}>
                <QuestionAnswer
                  question={mainincome[selectedLanguage]}
                  answer={
                    economics &&
                    economics.mainIncomeSource &&
                    this.multimapValue(economics.mainIncomeSource, {
                      agriculture_ac: "कृषि कार्य",
                      industry: "उधोग",
                      business: "व्यापार व्यवसाय",
                      job: "नोकरीजागिर",
                      pension: "पेन्सन",
                      rent_of_landor: "घर तथा जग्गा को भाडा",
                      rent_of_vehicl: "सवारी साधनको भाडा",
                      rent_of_machin: "मेसिन तथा उपकरणको भाडा",
                      interest: "व्याज",
                      remittances_fo: "विप्रेषण (वैदेशिक रोजगार)",
                      remittances_in: "विप्रेषण (आन्तरिक)",
                      daily_labour: "दैनिक ज्याला / मजदुरी",
                      other_earn_res: "अन्य"
                    })
                  }
                />
              </Grid>
            </Grid> */}
            <Grid
              item
              container
              direction="row"
              className={classes.eachQnAns}
            />
            <Grid item xs={6}>
              <QuestionAnswer
                question={yearlyExpenditure[selectedLanguage]}
                answer={
                  economics.totalExpenditure &&
                  getNepalNumber(economics.totalExpenditure)
                }
              />
            </Grid>
            <Grid item xs={6}>
              <QuestionAnswer
                question={yearlyIncome[selectedLanguage]}
                answer={
                  economics.totalIncome && getNepalNumber(economics.totalIncome)
                }
              />
            </Grid>
            <Grid item container direction="row" className={classes.eachQnAns}>
              <Grid item xs={6}>
                <QuestionAnswer
                  question={foodForMonthQuestion[selectedLanguage]}
                  answer={
                    economics.foodForMonth &&
                    mapValue(economics.foodForMonth, {
                      "3_month": "३ महिना",
                      "4_6_month": "४ देखि ६ महिना",
                      "7_9_month": "७ देखि ९ महिना सम्म",
                      "9_month_above": "९ महिना भन्दा बढी",
                      "12_months": "१२ महिना मजदुरी गरेर खाने"
                    })
                  }
                />
              </Grid>
              {/* {economics.foodForMonth !== "9_month_above" &&
								economics.foodForMonth !== "12_months" && (
									<Grid item xs={6}>
										<QuestionAnswer
											question={foodAlternateQuestion[selectedLanguage]}
											answer={
												economics.foodAlternate &&
												this.multimapValue(economics.foodAlternate, {
													saving_amount: "संचित घनको प्रयोग गरेको",
													borrow_amount: "पैचो सापटी लिएको",
													loan_amount: "ऋण लिएको",
													im_imovable_pr: "चल-अचल सम्पति विक्री गरेको",
													others: "अन्य"
												})
											}
										/>
									</Grid>
								)} */}
            </Grid>
            <Grid item container direction="row" className={classes.eachQnAns}>
              <Grid item xs={6}>
                <QuestionAnswer
                  question={loanGiven[selectedLanguage]}
                  answer={this.getYesNo(economics.cashLoanGiven)}
                />
              </Grid>
              {economics.cashLoanGiven && (
                <Grid item xs={6}>
                  <QuestionAnswer
                    question={loanGivenInterest[selectedLanguage]}
                    answer={getNepalNumber(economics.cashLoanRate)}
                  />
                </Grid>
              )}
            </Grid>
            <Grid item container direction="row" className={classes.eachQnAns}>
              <Grid item xs={6}>
                <QuestionAnswer
                  question={loanTaken[selectedLanguage]}
                  answer={this.getYesNo(economics.loanTakenInFamily)}
                />
              </Grid>
              {economics.loanTakenInFamily && (
                <Grid item xs={6}>
                  <QuestionAnswer
                    question={loanTakingReasonQuestion[selectedLanguage]}
                    answer={this.multimapValue(economics.loanTakingReason, {
                      loan_business: "व्यापार",
                      loan_education: "छोरा /छोरी पढाउन",
                      loan_health: "स्वस्थय",
                      loan_agricultu: "कृषि",
                      loan_house: "घरायसी उपयोग गर्ने",
                      loan_rituals: "जन्म/मृत्यु/विवाह /ब्रतबन्ध",
                      loan_festival: "चार्ड पर्व मनाउन",
                      loan_building: "घर निर्माण का लागि",
                      loan_land: "जग्गा जमिन किन्न का लागि",
                      outside_countr: "विदेश जानको लागि",
                      loan_other: "अन्य"
                    })}
                  />
                </Grid>
              )}
            </Grid>
            {economics.loanTakenInFamily && (
              <Grid item container direction="row">
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell>{loanType[selectedLanguage]}</TableCell>
                      {/* <TableCell align="right">
                        {loanInterest && loanInterest[selectedLanguage]}
                      </TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {economics.loan.map(row => (
                      <TableRow key={row.id}>
                        <TableCell component="th" scope="row">
                          {mapValue(row.name, {
                            Bank: "बैंक बाट",
                            Person: "व्यक्ति बाट",
                            Sahakari: "सहकारी बाट",
                            Laghubitta: "लघुबित्त बाट",
                            Farmer: "कृषक समुह",
                            Women: "महिला समुह"
                          })}
                        </TableCell>
                        {/* <TableCell align="right">
                          {row && row.rate && getNepalNumber(row.rate)} %
                        </TableCell> */}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto"
  },
  table: {
    width: "auto"
  }
});

SearchByHouseNumber.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SearchByHouseNumber);
