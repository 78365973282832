import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import { yes, no, selectedLanguage } from "../../../../../variable/global";
import {
  QuestionAnswer,
  Title
} from "../../../../common/frequent/QuestionAnswer";
import getNepalNumber from "../../../../../utils/getNepaliNumber";
import {
  liveStockInfo,
  livestockName,
  livestockDetail,
  animalCount,
  annualIncome
} from "../../../../../variable/houseSurvey";

function mapValue(value, obj) {
  return obj[value];
}

class SearchByHouseNumber extends Component {
  getYesNo = data => (data ? yes[selectedLanguage] : no[selectedLanguage]);
  render() {
    const { classes, livestock } = this.props;
    return (
      <Fragment>
        {livestock && (
          <Grid item container direction="row">
            <Title title={liveStockInfo} />
            <Grid item container>
              <Grid item container direction="row">
                <QuestionAnswer
                  question={livestockDetail[selectedLanguage]}
                  answer={this.getYesNo(livestock.hasAnimal)}
                />
              </Grid>

              {livestock.hasAnimal && (
                <Grid item container direction="column">
                  <Grid item>
                    <Table className={classes.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">
                            {livestockName[selectedLanguage]}
                          </TableCell>
                          <TableCell align="left">
                            {animalCount[selectedLanguage]}
                          </TableCell>
                          <TableCell align="left">
                            {annualIncome[selectedLanguage]}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {livestock &&
                          livestock.animals &&
                          livestock.animals.map((row, key) => (
                            <TableRow key={key}>
                              <TableCell align="left">
                                {mapValue(row.name, {
                                  cow: "गाई/बाछी",
                                  buffallo: "भैसी",
                                  "goat/boka": "खसी /बोका/बाख्रा",
                                  ranga: "राँगा / पाडा / पाडी",
                                  pig: "सुँगुर / बंगुर",
                                  sheep: "भेडा/च्यांग्रा",
                                  garu: "गोरु",
                                  gadha: "गधा/घोडा/ खच्चड",
                                  yak: "याक",
                                  chicken: "कुखुरा/हाँस",
                                  austrich: "अस्ट्रिच",
                                  pegion: "परेवा"
                                })}
                              </TableCell>
                              <TableCell align="left">
                                {row.animalCount &&
                                  getNepalNumber(row.animalCount)}
                              </TableCell>
                              <TableCell align="left">
                                {row.annualIncome &&
                                  getNepalNumber(row.annualIncome)}
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
      </Fragment>
    );
  }
}

const styles = theme => ({
  table: {
    width: "auto"
  }
});

SearchByHouseNumber.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SearchByHouseNumber);
