import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import SearchOption from "../SearchOption";
import ToleFilterTable from "./ToleFilterTable";
import { withStyles } from "@material-ui/core/styles";
import Axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";
import ReactToPrint from "../../../common/frequent/print/ReactToPrint";
import Fab from "@material-ui/core/Fab";
import PrintIcon from "@material-ui/icons/Print";

class index extends Component {
	constructor(props) {
		super(props);
		this.state = {
			wardData: 0,
			analysisList: [],
			loading: false,
			beforePrint: false,
			component: null,
			tagList: [],
			houses: [],
			open: false,
		};
		this.myRef = React.createRef();
	}

	handleChange = (name, value) => {
		this.setState({ [name]: value });
	};

	componentDidUpdate(prevProps, prevState) {
		if (prevState.wardData !== this.state.wardData) {
			Axios.get(`/api/toleReport/toleList/${this.state.wardData}`)
				.then((res) => {
					// console.log("res", res);
					this.setState({ tagList: res.data });
				})
				.catch((err) => console.log(err));
		}
	}

	fetchData(option) {
		Axios.get(`/api/toleReport/houseList/${option}`)
			.then((res) => {
				console.log("houselist", res.data);
				let { houses, analysisList } = this.state;
				houses.push(res.data);
				this.setState({
					houses,
					loading: analysisList.length !== houses.length,
				});
				console.log("houses", this.state.houses);
			})
			.catch((err) => {
				console.error(err);
				// dispatch({
				// 	type: GET_ERRORS,
				// 	payload: err.response.data
				// });
			});
	}

	clearData = () => this.setState({ houses: [], loading: true });

	fetchReport = () => {
		this.clearData();
		const { analysisList, wardData } = this.state;
		// const ward = wardData === 0 ? {} : { wardNumber: wardData };
		analysisList.map((analysis, i) => {
			this.fetchData(analysis);
		});
	};

	render() {
		const { classes } = this.props;
		const { analysisList, wardData, loading, tagList, houses } = this.state;
		return (
			<div>
				<SearchOption
					analysisList={analysisList}
					wardData={wardData}
					handleChange={this.handleChange}
					optionList={tagList}
					onSubmit={this.fetchReport}
					isWard={true}
				/>
				<Grid>
					{houses.length !== 0 &&
						houses.map((each) => (
							<Grid
								container
								direction="row"
								justify="center"
								alignItems="center"
							>
								<Grid item xs={8} className="print-break-after">
									<ToleFilterTable
										rows={each}
										title="घरको विवरण"
										subtitle="वडा तथा घर न. को आधरमा"
										analysisList={analysisList}
									/>
								</Grid>
							</Grid>
						))}
				</Grid>
				{loading && (
					<Grid container direction="row" justify="center" alignItems="center">
						<Grid item>
							<ClipLoader
								sizeUnit={"px"}
								size={84}
								color={"#00ACC1"}
								loading={true}
							/>
						</Grid>
					</Grid>
				)}
			</div>
		);
	}
}

const styles = (theme) => ({});

export default withStyles(styles)(index);
