import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import getNepalNumber from "../../../../../utils/getNepaliNumber";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { no, selectedLanguage, yes } from "../../../../../variable/global";
import {
  QuestionAnswer,
  Title
} from "../../../../common/frequent/QuestionAnswer";
import {
  alcoholUsage,
  alcoholCount,
  alcoholUsageCard,
  effectiveFromCard,
  alcoholUsageExpense,
  problemFromAlcohol,
  deathFromAlcohol,
  deathCount,
  alcoholTitle
} from "../../../../../variable/houseSurvey";

function mapValue(value, obj) {
  return obj[value];
}

class Alcohol extends Component {
  getYesNo = data => (data ? yes[selectedLanguage] : no[selectedLanguage]);
  multimapValue = (name, object) =>
    name && name.map(each => object[each]).join(", ");
  render() {
    const { classes, alcohol } = this.props;
    return (
      <Grid container item direction="column" className={classes.container}>
        <Title title={alcoholTitle} />
        <Grid item container direction="column">
          <Grid
            item
            container
            direction="row"
            className={classes.eachQnAns}
            spacing={24}
          >
            <Grid item xs={6}>
              <QuestionAnswer
                question={alcoholUsage[selectedLanguage]}
                answer={this.getYesNo(alcohol.alcoholUse.anyAlchoholUsage)}
              />
            </Grid>
            {alcohol.alcoholUse.anyAlchoholUsage === true && (
              <Grid item xs={6}>
                <QuestionAnswer
                  question={alcoholCount[selectedLanguage]}
                  answer={getNepalNumber(alcohol.alcoholUse.count)}
                />
              </Grid>
            )}
          </Grid>

          {alcohol.alcoholUse.anyAlchoholUsage === true && (
            <Grid item container direction="row" className={classes.eachQnAns}>
              <Grid item xs={6}>
                <QuestionAnswer
                  question={problemFromAlcohol[selectedLanguage]}
                  answer={
                    alcohol &&
                    alcohol.alcoholUse &&
                    this.multimapValue(
                      alcohol.alcoholUse.issueInFamilyFromAlcohol,
                      {
                        family_conflit: "पारिवारिक कलह",
                        social_effect: "सामाजिक असर",
                        health_problem: "स्वास्थ्यमा प्रभाव",
                        economic_issue: "आर्थिक प्रभाव",
                        crime: "हत्या हिंशा",
                        fight: "कुटपिट"
                      }
                    )
                  }
                />
              </Grid>
              {/* <Grid item xs={6}>
                <QuestionAnswer
                  question={deathFromAlcohol[selectedLanguage]}
                  answer={this.getYesNo(alcohol.alcoholUse.anyDeathFromAlcohol)}
                />
              </Grid> */}
            </Grid>
          )}
          {/* {alcohol.alcoholUse.anyDeathFromAlcohol === true && (
            <Grid item container direction="row" className={classes.eachQnAns}>
              <Grid item xs={6}>
                <QuestionAnswer
                  question={deathCount[selectedLanguage]}
                  answer={getNepalNumber(alcohol.alcoholUse.deathCount)}
                />
              </Grid>
            </Grid>
          )} */}
        </Grid>
      </Grid>
    );
  }
}

const styles = theme => ({
  table: {
    width: "auto"
  },
  container: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 2
  }
});

Alcohol.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Alcohol);
