const agriTotalRopani = { nepali: "रोपनी", english: "" };
const agriTotalAana = { nepali: "आना", english: "" };
const agriTotalPaisa = { nepali: "पैसा", english: "" };
const agriOtherTotalRopani = { nepali: "रोपनी", english: "" };
const agriOtherTotalAana = { nepali: "आना", english: "" };
const agriOtherTotalPaisa = { nepali: "पैसा", english: "" };
const agriProductionMuri = { nepali: "मुरी", english: "" };
const agriProductionPathi = { nepali: "पाथी", english: "" };
const agriSaleMuri = { nepali: "मुरी", english: "" };
const agriSalePathi = { nepali: "पाथी", english: "" };
const agriSaleQuantity = {
  nepali: "उत्पादन वाट बार्षिक आम्दानी कति हो ?",
  english: ""
};

const agriDhanRopani = { nepali: "रोपनी", english: "" };
const agriDhanAana = { nepali: "आना", english: "" };
const agriDhanPaisa = { nepali: "पैसा", english: "" };

const agriDhanProductionMuri = { nepali: "मुरी", english: "" };
const agriDhanProductionPathi = { nepali: "पाथी", english: "" };
const agriDhanSaleMuri = { nepali: "मुरी", english: "" };
const agriDhanSalePathi = { nepali: "पाथी", english: "" };
const agriDhanSaleQuantity = {
  nepali: "उत्पादन वाट बार्षिक आम्दानी कति हो ?",
  english: ""
};
const agriDhanProductionKg = { nepali: "धान खेति उत्पादन के. जि", english: "" };
const agriDhanSaleKg = { nepali: "धानखेति विक्री परिमाण के .जि", english: "" };

const agriMakaiRopani = { nepali: "रोपनी", english: "" };
const agriMakaiAana = { nepali: "आना", english: "" };
const agriMakaiPaisa = { nepali: "पैसा", english: "" };
const agriMakaiProductionMuri = { nepali: "मुरी", english: "" };
const agriMakaiProductionPathi = { nepali: "पाथी", english: "" };
const agriMakaiSaleMuri = { nepali: "मुरी", english: "" };
const agriMakaiSalePathi = { nepali: "पाथी", english: "" };
const agriMakaiSaleQuantity = {
  nepali: "उत्पादन वाट बार्षिक आम्दानी कति हो ?",
  english: ""
};
const agriMakaiProductionKg = {
  nepali: "मकै खेति उत्पादन के. जि",
  english: ""
};
const agriMakaiSaleKg = { nepali: "मकैखेति विक्री परिमाण के .जि", english: "" };
const agriKodoRopani = { nepali: "रोपनी", english: "" };
const agriKodoAana = { nepali: "आना", english: "" };
const agriKodoPaisa = { nepali: "पैसा", english: "" };
const agriKodoProductionMuri = { nepali: "मुरी", english: "" };
const agriKodoProductionPathi = { nepali: "पाथी", english: "" };
const agriKodoSaleMuri = { nepali: "मुरी", english: "" };
const agriKodoSalePathi = { nepali: "पाथी", english: "" };
const agriKodoSaleQuantity = {
  nepali: "उत्पादन वाट बार्षिक आम्दानी कति हो ?",
  english: ""
};
const agriKodoProductionKg = {
  nepali: "कोदो खेति उत्पादन के. जि",
  english: ""
};
const agriKodoSaleKg = {
  nepali: "कोदो उत्पादन विक्री परिमाण के .जि",
  english: ""
};
const agriFaparRopani = { nepali: "रोपनी", english: "" };
const agriFaparAana = { nepali: "आना", english: "" };
const agriFaparPaisa = { nepali: "पैसा", english: "" };
const agriFaparProductionMuri = { nepali: "मुरी", english: "" };
const agriFaparProductionPathi = { nepali: "पाथी", english: "" };
const agriFaparSaleMuri = { nepali: "मुरी", english: "" };
const agriFaparSalePathi = { nepali: "पाथी", english: "" };
const agriFaparSaleQuantity = {
  nepali: "उत्पादन वाट बार्षिक आम्दानी कति हो ?",
  english: ""
};
const agriFaparProductionKg = {
  nepali: "फापर खेति उत्पादन के. जि",
  english: ""
};
const agriFaparSaleKg = {
  nepali: "फापर उत्पादन विक्री परिमाण के .जि",
  english: ""
};

const agriGahuProductionKg = {
  nepali: "गहू/ जौ खेति उत्पादन के. जि",
  english: ""
};
const agriGahuSaleKg = {
  nepali: "गहू/ जौ उत्पादन विक्री परिमाण के .जि",
  english: ""
};
const agriCropIncome = {
  nepali: "अन्नवाली उत्पादन वाट बार्षिक आम्दानी कति हो ?",
  english: ""
};
const agriCropExpense = {
  nepali: "अन्नवाली खेतीमा हुने बार्षिक लागत खर्च कति हुन्छ? (रु.)",
  english: ""
};
const agriCropArea = {
  nepali: "अन्नवाली खेतीबाट हुने बार्षिक आम्दानि कति हो ? (रु.)",
  english: ""
};

//DaalStart
const agriBlackDaalRopani = { nepali: "रोपनी", english: "" };
const agriBlackDaalAana = { nepali: "आना", english: "" };
const agriBlackDaalPaisa = { nepali: "पैसा", english: "" };
const agriBlackDaalProductionMuri = { nepali: "मुरी", english: "" };
const agriBlackDaalProductionPathi = { nepali: "पाथी", english: "" };
const agriBlackDaalSaleMuri = { nepali: "मुरी", english: "" };
const agriBlackDaalSalePathi = { nepali: "पाथी", english: "" };
const agriBlackDaalProductionKg = {
  nepali: "कालो दाल खेति उत्पादन के. जि",
  english: ""
};
const agriBlackDaalSaleKg = {
  nepali: "कालो दाल उत्पादन विक्री परिमाण के .जि",
  english: ""
};

const agriRaharRopani = { nepali: "रोपनी", english: "" };
const agriRaharAana = { nepali: "आना", english: "" };
const agriRaharPaisa = { nepali: "पैसा", english: "" };
const agriRaharProductionMuri = { nepali: "मुरी", english: "" };
const agriRaharProductionPathi = { nepali: "पाथी", english: "" };
const agriRaharSaleMuri = { nepali: "मुरी", english: "" };
const agriRaharSalePathi = { nepali: "पाथी", english: "" };

const agriMusuriRopani = { nepali: "रोपनी", english: "" };
const agriMusuriAana = { nepali: "आना", english: "" };
const agriMusuriPaisa = { nepali: "पैसा", english: "" };
const agriMusuriProductionMuri = { nepali: "मुरी", english: "" };
const agriMusuriProductionPathi = { nepali: "पाथी", english: "" };
const agriMusuriSaleMuri = { nepali: "मुरी", english: "" };
const agriMusuriSalePathi = { nepali: "पाथी", english: "" };
const agriMusuriProductionKg = {
  nepali: "राजमा खेति उत्पादन के. जि",
  english: ""
};
const agriMusuriSaleKg = {
  nepali: "राजमा उत्पादन विक्री परिमाण के .जि",
  english: ""
};

const agriChanaRopani = { nepali: "रोपनी", english: "" };
const agriChanaAana = { nepali: "आना", english: "" };
const agriChanaPaisa = { nepali: "पैसा", english: "" };
const agriChanaProductionMuri = { nepali: "मुरी", english: "" };
const agriChanaProductionPathi = { nepali: "पाथी", english: "" };
const agriChanaSaleMuri = { nepali: "मुरी", english: "" };
const agriChanaSalePathi = { nepali: "पाथी", english: "" };
const agriChanaProductionKg = {
  nepali: "मटर /केराउ खेति उत्पादन के. जि",
  english: ""
};
const agriChanaSaleKg = {
  nepali: "मटर /केराउ उत्पादन विक्री परिमाण के .जि",
  english: ""
};

const agriBhatmasRopani = { nepali: "रोपनी", english: "" };
const agriBhatmasAana = { nepali: "आना", english: "" };
const agriBhatmasPaisa = { nepali: "पैसा", english: "" };
const agriBhatmasProductionMuri = { nepali: "मुरी", english: "" };
const agriBhatmasProductionPathi = { nepali: "पाथी", english: "" };
const agriBhatmasSaleMuri = { nepali: "मुरी", english: "" };
const agriBhatmasSalePathi = { nepali: "पाथी", english: "" };
const agriBhatmasProductionKg = {
  nepali: "भट्मास दालखेति उत्पादन (के.जिमा )",
  english: ""
};
const agriBhatmasSaleKg = {
  nepali: "भट्मास दालखेति विक्री परिमाण (के.जिमा)",
  english: ""
};

const agriSimiProductionKg = {
  nepali: "सिमि खेति उत्पादन के. जि",
  english: ""
};
const agriSimiSaleKg = {
  nepali: "सिमि उत्पादन विक्री परिमाण के .जि",
  english: ""
};

const agriGahatRopani = { nepali: "रोपनी", english: "" };
const agriGahatAana = { nepali: "आना", english: "" };
const agriGahatPaisa = { nepali: "पैसा", english: "" };
const agriGahatProductionMuri = { nepali: "मुरी", english: "" };
const agriGahatProductionPathi = { nepali: "पाथी", english: "" };
const agriGahatSaleMuri = { nepali: "मुरी", english: "" };
const agriGahatSalePathi = { nepali: "पाथी", english: "" };

const agriMashyamRopani = { nepali: "रोपनी", english: "" };
const agriMashyamAana = { nepali: "आना", english: "" };
const agriMashyamPaisa = { nepali: "पैसा", english: "" };
const agriMashyamProductionMuri = { nepali: "मुरी", english: "" };
const agriMashyamProductionPathi = { nepali: "पाथी", english: "" };
const agriMashyamSaleMuri = { nepali: "मुरी", english: "" };
const agriMashyamSalePathi = { nepali: "पाथी", english: "" };

const agriToriRopani = { nepali: "रोपनी", english: "" };
const agriToriAana = { nepali: "आना", english: "" };
const agriToriPaisa = { nepali: "पैसा", english: "" };
const agriToriProductionMuri = { nepali: "मुरी", english: "" };
const agriToriProductionPathi = { nepali: "पाथी", english: "" };
const agriToriSaleMuri = { nepali: "मुरी", english: "" };
const agriToriSalePathi = { nepali: "मुरी", english: "" };
const agriToriSaleQuantity = {
  nepali: "उत्पादन वाट बार्षिक आम्दानी कति हो ?",
  english: ""
};
const agriToriProductionKg = {
  nepali: "तोरी /सर्स्यु खेति उत्पादन के. जि",
  english: ""
};
const agriToriSaleKg = {
  nepali: "तोरी /सर्स्यु उत्पादन विक्री परिमाण के .जि",
  english: ""
};

const agriSarsiuRopani = { nepali: "रोपनी", english: "" };
const agriSarsiuAana = { nepali: "आना", english: "" };
const agriSarsiuPaisa = { nepali: "पैसा", english: "" };
const agriSarsiuProductionMuri = { nepali: "मुरी", english: "" };
const agriSarsiuProductionPathi = { nepali: "पाथी", english: "" };
const agriSarsiuSaleMuri = { nepali: "मुरी", english: "" };
const agriSarsiuSalePathi = { nepali: "मुरी", english: "" };
const agriSarsiuSaleQuantity = {
  nepali: "उत्पादन वाट बार्षिक आम्दानी कति हो ?",
  english: ""
};

const agriAalashRopani = { nepali: "रोपनी", english: "" };
const agriAalashAana = { nepali: "आना", english: "" };
const agriAalashPaisa = { nepali: "पैसा", english: "" };
const agriAalashProductionMuri = { nepali: "मुरी", english: "" };
const agriAalashProductionPathi = { nepali: "पाथी", english: "" };
const agriAalashSaleMuri = { nepali: "मुरी", english: "" };
const agriAalashSalePathi = { nepali: "मुरी", english: "" };
const agriAalashSaleQuantity = {
  nepali: "उत्पादन वाट बार्षिक आम्दानी कति हो ?",
  english: ""
};
const agriAalashProductionKg = {
  nepali: "आलस/तिल खेति उत्पादन के. जि",
  english: ""
};
const agriAalashSaleKg = {
  nepali: "आलस/तिल उत्पादन विक्री परिमाण के .जि",
  english: ""
};

const agriFilungeyRopani = { nepali: "रोपनी", english: "" };
const agriFilungeyAana = { nepali: "आना", english: "" };
const agriFilungeyPaisa = { nepali: "पैसा", english: "" };
const agriFilungeyProductionMuri = { nepali: "मुरी", english: "" };
const agriFilungeyProductionPathi = { nepali: "पाथी", english: "" };
const agriFilungeySaleMuri = { nepali: "मुरी", english: "" };
const agriFilungeySalePathi = { nepali: "मुरी", english: "" };
const agriFilungeySaleQuantity = {
  nepali: "उत्पादन वाट बार्षिक आम्दानी कति हो ?",
  english: ""
};
const agriFilungeyProductionKg = {
  nepali: "फिलुंगे खेति उत्पादन के. जि",
  english: ""
};
const agriFilungeySaleKg = {
  nepali: "फिलुंगे उत्पादन विक्री परिमाण के .जि",
  english: ""
};

const agriTeelRopani = { nepali: "रोपनी", english: "" };
const agriTeelAana = { nepali: "आना", english: "" };
const agriTeelPaisa = { nepali: "पैसा", english: "" };
const agriTeelProductionMuri = { nepali: "मुरी", english: "" };
const agriTeelProductionPathi = { nepali: "पाथी", english: "" };
const agriTeelSaleMuri = { nepali: "मुरी", english: "" };
const agriTeelSalePathi = { nepali: "मुरी", english: "" };
const agriTeelSaleQuantity = {
  nepali: "उत्पादन वाट बार्षिक आम्दानी कति हो ?",
  english: ""
};

const agriSunflowerRopani = { nepali: "रोपनी", english: "" };
const agriSunflowerAana = { nepali: "आना", english: "" };
const agriSunflowerPaisa = { nepali: "पैसा", english: "" };
const agriSunflowerProductionMuri = { nepali: "मुरी", english: "" };
const agriSunflowerProductionPathi = { nepali: "पाथी", english: "" };
const agriSunflowerSaleMuri = { nepali: "मुरी", english: "" };
const agriSunflowerSalePathi = { nepali: "मुरी", english: "" };
const agriSunflowerSaleQuantity = {
  nepali: "उत्पादन वाट बार्षिक आम्दानी कति हो ?",
  english: ""
};
const agriSunflowerProductionKg = {
  nepali: "सिलाम खेति उत्पादन के. जि",
  english: ""
};
const agriSunflowerSaleKg = {
  nepali: "सिलाम उत्पादन विक्री परिमाण के .जि",
  english: ""
};

const agriDaalIncome = {
  nepali: "दाल उत्पादन वाट आम्दानी कति हो ?",
  english: ""
};

const agriDaalExpense = {
  nepali: "दाल खेतीमा हुने बार्षिक लागत खर्च कति हुन्छ? (रु.)",
  english: ""
};

const agriDaalArea = {
  nepali: "दाल खेतीबाट हुने बार्षिक आम्दानि कति हो ? (रु.)",
  english: ""
};
const agriOilIncome = {
  nepali: "तेलहन उत्पादन वाट बार्षिक आम्दानी कति हो ?",
  english: ""
};
const agriOilExpense = {
  nepali: "तेलहन खेतीमा हुने बार्षिक लागत खर्च कति हुन्छ? (रु.)",
  english: ""
};
const agriOilArea = {
  nepali: "तेलहन खेतीबाट हुने बार्षिक आम्दानि कति हो ? (रु.)",
  english: ""
};
const agriVegetableIncome = {
  nepali: "तरकारी उत्पादन वाट बार्षिक आम्दानी कति हो ?",
  english: ""
};
const agriVegetableExpense = {
  nepali: "तरकारी खेतीमा हुने बार्षिक लागत खर्च कति हुन्छ? (रु.)",
  english: ""
};
const agriVegetableArea = {
  nepali: "तरकारी खेतीबाट हुने बार्षिक आम्दानि कति हो ? (रु.)",
  english: ""
};
const agriFruitIncome = {
  nepali: "फलफुल उत्पादन वाट बार्षिक आम्दानी कति हो ?",
  english: ""
};
const agriFruitExpense = {
  nepali: "फलफुल खेतीमा हुने बार्षिक लागत खर्च कति हुन्छ? (रु.)",
  english: ""
};
const agriFruitArea = {
  nepali: "फलफुल खेतीबाट हुने बार्षिक आम्दानि कति हो ? (रु.)",
  english: ""
};
const agriCashCropIncome = {
  nepali: "नगदेवाली उत्पादन वाट बार्षिक आम्दानी कति हो ?",
  english: ""
};
const agriCashCropExpense = {
  nepali: "नगदेवाली खेतीमा हुने बार्षिक लागत खर्च कति हुन्छ? (रु.)",
  english: ""
};
const agriCashCropArea = {
  nepali: "नगदेवाली खेतीबाट हुने बार्षिक आम्दानि कति हो ? (रु.)",
  english: ""
};

const agriPotatoRopani = { nepali: "रोपनी", english: "" };
const agriPotatoAana = { nepali: "आना", english: "" };
const agriPotatoPaisa = { nepali: "पैसा", english: "" };
const agriPotatoProductionKg = {
  nepali: "आलु खेति उत्पादन के. जि",
  english: ""
};
const agriPotatoSaleKg = {
  nepali: "आलु उत्पादन विक्री परिमाण के .जि",
  english: ""
};

const agriCabbageRopani = { nepali: "रोपनी", english: "" };
const agriCabbageAana = { nepali: "आना", english: "" };
const agriCabbagePaisa = { nepali: "पैसा", english: "" };
const agriCabbageProductionKg = {
  nepali: "बन्दा/काउली /बोडी खेति उत्पादन के. जि",
  english: ""
};
const agriCabbageSaleKg = {
  nepali: "बन्दा/काउली /बोडी उत्पादन विक्री परिमाण के .जि",
  english: ""
};

const agriSaagRopani = { nepali: "रोपनी", english: "" };
const agriSaagAana = { nepali: "आना", english: "" };
const agriSaagPaisa = { nepali: "पैसा", english: "" };
const agriSaagProductionKg = {
  nepali: "रायो (साग सब्जी ) खेति उत्पादन के. जि",
  english: ""
};
const agriSaagSaleKg = {
  nepali: "रायो (साग सब्जी ) उत्पादन विक्री परिमाण के .जि",
  english: ""
};

const agriTomatoRopani = { nepali: "रोपनी", english: "" };
const agriTomatoAana = { nepali: "आना", english: "" };
const agriTomatoPaisa = { nepali: "पैसा", english: "" };
const agriTomatoProductionKg = {
  nepali: "गोलभेडा खेति उत्पादन के. जि",
  english: ""
};
const agriTomatoSaleKg = {
  nepali: "गोलभेडा उत्पादन विक्री परिमाण के .जि",
  english: ""
};

const agriCucumberRopani = { nepali: "रोपनी", english: "" };
const agriCucumberAana = { nepali: "आना", english: "" };
const agriCucumberPaisa = { nepali: "पैसा", english: "" };
const agriCucumberProductionKg = {
  nepali: "काक्रो/लौका /मुला खेति उत्पादन के. जि",
  english: ""
};
const agriCucumberSaleKg = {
  nepali: "काक्रो/लौका /मुला उत्पादन विक्री परिमाण के .जि",
  english: ""
};

const agriGhiraulaRopani = { nepali: "रोपनी", english: "" };
const agriGhiraulaAana = { nepali: "आना", english: "" };
const agriGhiraulaPaisa = { nepali: "पैसा", english: "" };
const agriGhiraulaProductionKg = {
  nepali: "करेला/घिरौला/फर्सी खेति उत्पादन के. जि",
  english: ""
};
const agriGhiraulaSaleKg = {
  nepali: "करेला/घिरौला/फर्सी उत्पादन विक्री परिमाण के .जि",
  english: ""
};

const agriMangoRopani = { nepali: "रोपनी", english: "" };
const agriMangoAana = { nepali: "आना", english: "" };
const agriMangoPaisa = { nepali: "पैसा", english: "" };
const agriMangoProductionMuri = { nepali: "मुरी", english: "" };
const agriMangoProductionPathi = { nepali: "पाथी", english: "" };
const agriMangoSaleMuri = { nepali: "मुरी", english: "" };
const agriMangoSalePathi = { nepali: "मुरी", english: "" };
const agriMangoSaleQuantity = {
  nepali: "उत्पादन वाट बार्षिक आम्दानी कति हो ?",
  english: ""
};
const agriMangoProductionKg = {
  nepali: "आप/लिचि खेति उत्पादन के. जि",
  english: ""
};
const agriMangoSaleKg = {
  nepali: "आप/लिचि उत्पादन विक्री परिमाण के .जि",
  english: ""
};

const agriAarubakhadaRopani = { nepali: "रोपनी", english: "" };
const agriAarubakhadaAana = { nepali: "आना", english: "" };
const agriAarubakhadaPaisa = { nepali: "पैसा", english: "" };

const agriAarubakhadaProductionMuri = { nepali: "मुरी", english: "" };
const agriAarubakhadaProductionPathi = { nepali: "पाथी", english: "" };
const agriAarubakhadaSaleMuri = { nepali: "मुरी", english: "" };
const agriAarubakhadaSalePathi = { nepali: "मुरी", english: "" };
const agriAarubakhadaSaleQuantity = {
  nepali: "उत्पादन वाट बार्षिक आम्दानी कति हो ?",
  english: ""
};

const agriBananaRopani = { nepali: "रोपनी", english: "" };
const agriBananaAana = { nepali: "आना", english: "" };
const agriBananaPaisa = { nepali: "पैसा", english: "" };
const agriBananaProductionMuri = { nepali: "मुरी", english: "" };
const agriBananaProductionPathi = { nepali: "पाथी", english: "" };
const agriBananaSaleMuri = { nepali: "मुरी", english: "" };
const agriBananaSalePathi = { nepali: "मुरी", english: "" };
const agriBananaSaleQuantity = {
  nepali: "उत्पादन वाट बार्षिक आम्दानी कति हो ?",
  english: ""
};
const agriBananaProductionKg = {
  nepali: "केरा खेति उत्पादन के. जि",
  english: ""
};
const agriBananaSaleKg = {
  nepali: "केरा उत्पादन विक्री परिमाण के .जि",
  english: ""
};

const agriOrangeRopani = { nepali: "रोपनी", english: "" };
const agriOrangeAana = { nepali: "आना", english: "" };
const agriOrangePaisa = { nepali: "पैसा", english: "" };
const agriOrangeProductionMuri = { nepali: "मुरी", english: "" };
const agriOrangeProductionPathi = { nepali: "पाथी", english: "" };
const agriOrangeSaleMuri = { nepali: "मुरी", english: "" };
const agriOrangeSalePathi = { nepali: "मुरी", english: "" };
const agriOrangeSaleQuantity = {
  nepali: "उत्पादन वाट बार्षिक आम्दानी कति हो ?",
  english: ""
};
const agriOrangeProductionKg = {
  nepali: "सुन्तला/जुनार /कागती (सुन्तला जात ) खेति उत्पादन के. जि",
  english: ""
};
const agriOrangeSaleKg = {
  nepali: "सुन्तला/जुनार /कागती (सुन्तला जात ) उत्पादन विक्री परिमाण के .जि",
  english: ""
};

const agriGuvaRopani = { nepali: "रोपनी", english: "" };
const agriGuvaAana = { nepali: "आना", english: "" };
const agriGuvaPaisa = { nepali: "पैसा", english: "" };
const agriGuvaProductionMuri = { nepali: "मुरी", english: "" };
const agriGuvaProductionPathi = { nepali: "पाथी", english: "" };
const agriGuvaSaleMuri = { nepali: "मुरी", english: "" };
const agriGuvaSalePathi = { nepali: "मुरी", english: "" };
const agriGuvaSaleQuantity = {
  nepali: "उत्पादन वाट बार्षिक आम्दानी कति हो ?",
  english: ""
};

const agriAppleRopani = { nepali: "रोपनी", english: "" };
const agriAppleAana = { nepali: "आना", english: "" };
const agriApplePaisa = { nepali: "पैसा", english: "" };
const agriAppleProductionMuri = { nepali: "मुरी", english: "" };
const agriAppleProductionPathi = { nepali: "पाथी", english: "" };
const agriAppleSaleMuri = { nepali: "मुरी", english: "" };
const agriAppleSalePathi = { nepali: "मुरी", english: "" };
const agriAppleSaleQuantity = {
  nepali: "उत्पादन वाट बार्षिक आम्दानी कति हो ?",
  english: ""
};
const agriAppleProductionKg = {
  nepali: "स्याउ खेति उत्पादन के. जि",
  english: ""
};
const agriAppleSaleKg = {
  nepali: "स्याउ उत्पादन विक्री परिमाण के .जि",
  english: ""
};

const agriKiwiRopani = { nepali: "रोपनी", english: "" };
const agriKiwiAana = { nepali: "आना", english: "" };
const agriKiwiPaisa = { nepali: "पैसा", english: "" };
const agriKiwiProductionMuri = { nepali: "मुरी", english: "" };
const agriKiwiProductionPathi = { nepali: "पाथी", english: "" };
const agriKiwiSaleMuri = { nepali: "मुरी", english: "" };
const agriKiwiSalePathi = { nepali: "मुरी", english: "" };
const agriKiwiSaleQuantity = {
  nepali: "उत्पादन वाट बार्षिक आम्दानी कति हो ?",
  english: ""
};
const agriKiwiProductionKg = {
  nepali: "किवि खेति उत्पादन के. जि",
  english: ""
};
const agriKiwiSaleKg = {
  nepali: "किवि उत्पादन विक्री परिमाण के .जि",
  english: ""
};

const agriNaspatiRopani = { nepali: "रोपनी", english: "" };
const agriNaspatiAana = { nepali: "आना", english: "" };
const agriNaspatiPaisa = { nepali: "पैसा", english: "" };
const agriNaspatiProductionMuri = { nepali: "मुरी", english: "" };
const agriNaspatiProductionPathi = { nepali: "पाथी", english: "" };
const agriNaspatiSaleMuri = { nepali: "मुरी", english: "" };
const agriNaspatiSalePathi = { nepali: "मुरी", english: "" };
const agriNaspatiSaleQuantity = {
  nepali: "उत्पादन वाट बार्षिक आम्दानी कति हो ?",
  english: ""
};

const agriAvogadoRopani = { nepali: "रोपनी", english: "" };
const agriAvogadoAana = { nepali: "आना", english: "" };
const agriAvogadoPaisa = { nepali: "पैसा", english: "" };
const agriAvogadoProductionMuri = { nepali: "मुरी", english: "" };
const agriAvogadoProductionPathi = { nepali: "पाथी", english: "" };
const agriAvogadoSaleMuri = { nepali: "मुरी", english: "" };
const agriAvogadoSalePathi = { nepali: "मुरी", english: "" };
const agriAvogadoSaleQuantity = {
  nepali: "उत्पादन वाट बार्षिक आम्दानी कति हो ?",
  english: ""
};
const agriAvogadoProductionKg = {
  nepali: "एभोकार्ड़ो खेति उत्पादन के. जि",
  english: ""
};
const agriAvogadoSaleKg = {
  nepali: "एभोकार्ड़ो उत्पादन विक्री परिमाण के .जि",
  english: ""
};

const agriAlaichiRopani = { nepali: "रोपनी", english: "" };
const agriAlaichiAana = { nepali: "आना", english: "" };
const agriAlaichiPaisa = { nepali: "पैसा", english: "" };
const agriAlaichiProductionMuri = { nepali: "मुरी", english: "" };
const agriAlaichiProductionPathi = { nepali: "पाथी", english: "" };
const agriAlaichiSaleMuri = { nepali: "मुरी", english: "" };
const agriAlaichiSalePathi = { nepali: "मुरी", english: "" };
const agriAlaichiProductionKg = {
  nepali: "अलैची खेति उत्पादन के. जि",
  english: ""
};
const agriAlaichiSaleKg = {
  nepali: "अलैची उत्पादन विक्री परिमाण के .जि",
  english: ""
};

const agriAmrisoRopani = { nepali: "रोपनी", english: "" };
const agriAmrisoAana = { nepali: "आना", english: "" };
const agriAmrisoPaisa = { nepali: "पैसा", english: "" };
const agriAmrisoProductionMuri = { nepali: "मुरी", english: "" };
const agriAmrisoProductionPathi = { nepali: "पाथी", english: "" };
const agriAmrisoSaleMuri = { nepali: "मुरी", english: "" };
const agriAmrisoSalePathi = { nepali: "मुरी", english: "" };
const agriAmrisoProductionKg = {
  nepali: "अम्रिसो खेति उत्पादन के. जि",
  english: ""
};
const agriAmrisoSaleKg = {
  nepali: "अम्रिसो उत्पादन विक्री परिमाण के .जि",
  english: ""
};

const agriTeaRopani = { nepali: "रोपनी", english: "" };
const agriTeaAana = { nepali: "आना", english: "" };
const agriTeaPaisa = { nepali: "पैसा", english: "" };
const agriTeaProductionKg = {
  nepali: "चिया खेति उत्पादन के. जि",
  english: ""
};
const agriTeaSaleKg = {
  nepali: "चिया उत्पादन विक्री परिमाण के .जि",
  english: ""
};

const agriCoffeeProductionKg = {
  nepali: "कफी खेति उत्पादन के. जि",
  english: ""
};
const agriCoffeeSaleKg = {
  nepali: "कफी उत्पादन विक्री परिमाण के .जि",
  english: ""
};

const agriGingerRopani = { nepali: "रोपनी", english: "" };
const agriGingerAana = { nepali: "आना", english: "" };
const agriGingerPaisa = { nepali: "पैसा", english: "" };
const agriGingerProductionMuri = { nepali: "मुरी", english: "" };
const agriGingerProductionPathi = { nepali: "पाथी", english: "" };
const agriGingerSaleMuri = { nepali: "मुरी", english: "" };
const agriGingerSalePathi = { nepali: "मुरी", english: "" };
const agriGingerProductionKg = {
  nepali: "अदुवा/बेसार खेति उत्पादन के. जि",
  english: ""
};
const agriGingerSaleKg = {
  nepali: "अदुवा/बेसार उत्पादन विक्री परिमाण के .जि",
  english: ""
};

const agriBessarRopani = { nepali: "रोपनी", english: "" };
const agriBessarAana = { nepali: "आना", english: "" };
const agriBessarPaisa = { nepali: "पैसा", english: "" };
const agriBessarProductionMuri = { nepali: "मुरी", english: "" };
const agriBessarProductionPathi = { nepali: "पाथी", english: "" };
const agriBessarSaleMuri = { nepali: "मुरी", english: "" };
const agriBessarSalePathi = { nepali: "मुरी", english: "" };

const agriGarlicRopani = { nepali: "रोपनी", english: "" };
const agriGarlicAana = { nepali: "आना", english: "" };
const agriGarlicPaisa = { nepali: "पैसा", english: "" };
const agriGarlicProductionMuri = { nepali: "मुरी", english: "" };
const agriGarlicProductionPathi = { nepali: "पाथी", english: "" };
const agriGarlicSaleMuri = { nepali: "मुरी", english: "" };
const agriGarlicSalePathi = { nepali: "मुरी", english: "" };
const agriGarlicProductionKg = {
  nepali: "लसुन/प्याज खेति उत्पादन के. जि",
  english: ""
};
const agriGarlicSaleKg = {
  nepali: "लसुन/प्याज उत्पादन विक्री परिमाण के .जि",
  english: ""
};

const agriOnionRopani = { nepali: "रोपनी", english: "" };
const agriOnionAana = { nepali: "आना", english: "" };
const agriOnionPaisa = { nepali: "पैसा", english: "" };
const agriOnionProductionMuri = { nepali: "मुरी", english: "" };
const agriOnionProductionPathi = { nepali: "पाथी", english: "" };
const agriOnionSaleMuri = { nepali: "मुरी", english: "" };
const agriOnionSalePathi = { nepali: "मुरी", english: "" };

const agriMushroomRopani = { nepali: "रोपनी", english: "" };
const agriMushroomAana = { nepali: "आना", english: "" };
const agriMushroomPaisa = { nepali: "पैसा", english: "" };
const agriMushroomProductionMuri = { nepali: "मुरी", english: "" };
const agriMushroomProductionPathi = { nepali: "पाथी", english: "" };
const agriMushroomSaleMuri = { nepali: "मुरी", english: "" };
const agriMushroomSalePathi = { nepali: "मुरी", english: "" };
const agriMushroomProductionKg = {
  nepali: "च्याउ खेति उत्पादन के. जि",
  english: ""
};
const agriMushroomSaleKg = {
  nepali: "च्याउ उत्पादन विक्री परिमाण के .जि",
  english: ""
};

const agriSugarcaneRopani = { nepali: "रोपनी", english: "" };
const agriSugarcaneAana = { nepali: "आना", english: "" };
const agriSugarcanePaisa = { nepali: "पैसा", english: "" };
const agriSugarcaneProductionMuri = { nepali: "मुरी", english: "" };
const agriSugarcaneProductionPathi = { nepali: "पाथी", english: "" };
const agriSugarcaneSaleMuri = { nepali: "मुरी", english: "" };
const agriSugarcaneSalePathi = { nepali: "मुरी", english: "" };
const agriSugarcaneProductionKg = {
  nepali: "उखु खेति उत्पादन के. जि",
  english: ""
};
const agriSugarcaneSaleKg = {
  nepali: "उखु उत्पादन विक्री परिमाण के .जि",
  english: ""
};

const agriNutRopani = { nepali: "रोपनी", english: "" };
const agriNutAana = { nepali: "आना", english: "" };
const agriNutPaisa = { nepali: "पैसा", english: "" };
const agriNutProductionMuri = { nepali: "मुरी", english: "" };
const agriNutProductionPathi = { nepali: "पाथी", english: "" };
const agriNutSaleMuri = { nepali: "मुरी", english: "" };
const agriNutSalePathi = { nepali: "मुरी", english: "" };
const agriNutProductionKg = {
  nepali: "बदाम खेति उत्पादन के. जि",
  english: ""
};
const agriNutSaleKg = {
  nepali: "बदाम उत्पादन विक्री परिमाण के .जि",
  english: ""
};

const agriChillyRopani = { nepali: "रोपनी", english: "" };
const agriChillyAana = { nepali: "आना", english: "" };
const agriChillyPaisa = { nepali: "पैसा", english: "" };
const agriChillyProductionMuri = { nepali: "मुरी", english: "" };
const agriChillyProductionPathi = { nepali: "पाथी", english: "" };
const agriChillySaleMuri = { nepali: "मुरी", english: "" };
const agriChillySalePathi = { nepali: "मुरी", english: "" };
const agriChillyProductionKg = {
  nepali: "खुर्सानी खेति उत्पादन के. जि",
  english: ""
};
const agriChillySaleKg = {
  nepali: "खुर्सानी उत्पादन विक्री परिमाण के .जि",
  english: ""
};

const agriFlowerRopani = { nepali: "रोपनी", english: "" };
const agriFlowerAana = { nepali: "आना", english: "" };
const agriFlowerPaisa = { nepali: "पैसा", english: "" };
const agriFlowerProductionKg = {
  nepali: "फुलखेती खेति उत्पादन के. जि",
  english: ""
};
const agriFlowerSaleKg = {
  nepali: "फुलखेती उत्पादन विक्री परिमाण के .जि",
  english: ""
};

const agriRudrakshProductionKg = {
  nepali: "रुद्राक्ष(दाना) खेति उत्पादन के. जि",
  english: ""
};

const agriRudrakshSaleKg = {
  nepali: "रुद्राक्ष(दाना) उत्पादन विक्री परिमाण के .जि",
  english: ""
};

const agribeeFishSilkSaleQuantity = {
  nepali: "उत्पादन वाट बार्षिक आम्दानी कति हो ?",
  english: ""
};
const agriOrganicFertilizer = { nepali: "स्थानीय अर्गानिक", english: "" };
const agriChemicalFertilizer = {
  nepali: "रासायनिक किट नासक औषधि",
  english: ""
};
const agrBothFertilizer = { nepali: "दुवै", english: "" };

module.exports = {
  agriTotalRopani,
  agriTotalAana,
  agriTotalPaisa,
  agriOtherTotalRopani,
  agriOtherTotalAana,
  agriOtherTotalPaisa,

  agriDhanRopani,
  agriDhanAana,
  agriDhanPaisa,
  agriDhanProductionMuri,
  agriDhanProductionPathi,
  agriDhanSaleMuri,
  agriDhanSalePathi,
  agriDhanSaleQuantity,
  agriDhanProductionKg,
  agriDhanSaleKg,
  agriRudrakshProductionKg,
  agriRudrakshSaleKg,

  agriMakaiRopani,
  agriMakaiAana,
  agriMakaiPaisa,
  agriMakaiProductionMuri,
  agriMakaiProductionPathi,
  agriMakaiSaleMuri,
  agriMakaiSalePathi,
  agriMakaiSaleQuantity,
  agriMakaiProductionKg,
  agriMakaiSaleKg,

  agriKodoRopani,
  agriKodoAana,
  agriKodoPaisa,
  agriKodoProductionMuri,
  agriKodoProductionPathi,
  agriKodoSaleMuri,
  agriKodoSalePathi,
  agriKodoSaleQuantity,
  agriKodoProductionKg,
  agriKodoSaleKg,

  agriFaparRopani,
  agriFaparAana,
  agriFaparPaisa,
  agriFaparProductionMuri,
  agriFaparProductionPathi,
  agriFaparSaleMuri,
  agriFaparSalePathi,
  agriFaparSaleQuantity,
  agriFaparProductionKg,
  agriFaparSaleKg,
  agriGahuProductionKg,
  agriGahuSaleKg,

  agriCropIncome,
  agriCropExpense,
  agriCropArea,
  agriDaalIncome,
  agriDaalExpense,
  agriDaalArea,
  agriOilIncome,
  agriOilExpense,
  agriOilArea,
  agriVegetableIncome,
  agriVegetableExpense,
  agriVegetableArea,
  agriFruitIncome,
  agriFruitExpense,
  agriFruitArea,
  agriCashCropIncome,
  agriCashCropExpense,
  agriCashCropArea,
  agriBlackDaalRopani,
  agriBlackDaalAana,
  agriBlackDaalPaisa,
  agriBlackDaalProductionMuri,
  agriBlackDaalProductionPathi,
  agriBlackDaalSaleMuri,
  agriBlackDaalSalePathi,
  agriBlackDaalProductionKg,
  agriBlackDaalSaleKg,
  agriRaharRopani,
  agriRaharAana,
  agriRaharPaisa,
  agriRaharProductionMuri,
  agriRaharProductionPathi,
  agriRaharSaleMuri,
  agriRaharSalePathi,
  agriMusuriRopani,
  agriMusuriAana,
  agriMusuriPaisa,
  agriMusuriProductionMuri,
  agriMusuriProductionPathi,
  agriMusuriSaleMuri,
  agriMusuriSalePathi,
  agriMusuriProductionKg,
  agriMusuriSaleKg,
  agriChanaRopani,
  agriChanaAana,
  agriChanaPaisa,
  agriChanaProductionMuri,
  agriChanaProductionPathi,
  agriChanaSaleMuri,
  agriChanaSalePathi,
  agriChanaProductionKg,
  agriChanaSaleKg,
  agriBhatmasRopani,
  agriBhatmasAana,
  agriBhatmasPaisa,
  agriBhatmasProductionMuri,
  agriBhatmasProductionPathi,
  agriBhatmasSaleMuri,
  agriBhatmasSalePathi,
  agriBhatmasProductionKg,
  agriBhatmasSaleKg,
  agriSimiProductionKg,
  agriSimiSaleKg,
  agriGahatRopani,
  agriGahatAana,
  agriGahatPaisa,
  agriGahatProductionMuri,
  agriGahatProductionPathi,
  agriGahatSaleMuri,
  agriGahatSalePathi,
  agriMashyamRopani,
  agriMashyamAana,
  agriMashyamPaisa,
  agriMashyamProductionMuri,
  agriMashyamProductionPathi,
  agriMashyamSaleMuri,
  agriMashyamSalePathi,
  agriToriRopani,
  agriToriAana,
  agriToriPaisa,
  agriToriProductionMuri,
  agriToriProductionPathi,
  agriToriSaleMuri,
  agriToriSalePathi,
  agriToriSaleQuantity,
  agriToriProductionKg,
  agriToriSaleKg,
  agriSarsiuRopani,
  agriSarsiuAana,
  agriSarsiuPaisa,
  agriSarsiuProductionMuri,
  agriSarsiuProductionPathi,
  agriSarsiuSaleMuri,
  agriSarsiuSalePathi,
  agriSarsiuSaleQuantity,
  agriAalashRopani,
  agriAalashAana,
  agriAalashPaisa,
  agriAalashProductionMuri,
  agriAalashProductionPathi,
  agriAalashSaleMuri,
  agriAalashSalePathi,
  agriAalashSaleQuantity,
  agriAalashProductionKg,
  agriAalashSaleKg,
  agriFilungeyRopani,
  agriFilungeyAana,
  agriFilungeyPaisa,
  agriFilungeyProductionMuri,
  agriFilungeyProductionPathi,
  agriFilungeySaleMuri,
  agriFilungeySalePathi,
  agriFilungeySaleQuantity,
  agriFilungeyProductionKg,
  agriFilungeySaleKg,
  agriTeelRopani,
  agriTeelAana,
  agriTeelPaisa,
  agriTeelProductionMuri,
  agriTeelProductionPathi,
  agriTeelSaleMuri,
  agriTeelSalePathi,
  agriTeelSaleQuantity,
  agriSunflowerRopani,
  agriSunflowerAana,
  agriSunflowerPaisa,
  agriSunflowerProductionMuri,
  agriSunflowerProductionPathi,
  agriSunflowerSaleMuri,
  agriSunflowerSalePathi,
  agriSunflowerSaleQuantity,
  agriSunflowerProductionKg,
  agriSunflowerSaleKg,
  agriPotatoRopani,
  agriPotatoAana,
  agriPotatoPaisa,
  agriPotatoProductionKg,
  agriPotatoSaleKg,
  agriCabbageRopani,
  agriCabbageAana,
  agriCabbagePaisa,
  agriCabbageProductionKg,
  agriCabbageSaleKg,
  agriSaagRopani,
  agriSaagAana,
  agriSaagPaisa,
  agriSaagProductionKg,
  agriSaagSaleKg,
  agriTomatoRopani,
  agriTomatoAana,
  agriTomatoPaisa,
  agriTomatoProductionKg,
  agriTomatoSaleKg,
  agriCucumberRopani,
  agriCucumberAana,
  agriCucumberPaisa,
  agriCucumberProductionKg,
  agriCucumberSaleKg,

  agriGhiraulaRopani,
  agriGhiraulaAana,
  agriGhiraulaPaisa,
  agriGhiraulaProductionKg,
  agriGhiraulaSaleKg,

  agriMangoRopani,
  agriMangoAana,
  agriMangoPaisa,
  agriMangoProductionMuri,
  agriMangoProductionPathi,
  agriMangoSaleMuri,
  agriMangoSalePathi,
  agriMangoSaleQuantity,
  agriMangoProductionKg,
  agriMangoSaleKg,

  agriAarubakhadaRopani,
  agriAarubakhadaAana,
  agriAarubakhadaPaisa,
  agriAarubakhadaProductionMuri,
  agriAarubakhadaProductionPathi,
  agriAarubakhadaSaleMuri,
  agriAarubakhadaSalePathi,
  agriAarubakhadaSaleQuantity,

  agriBananaRopani,
  agriBananaAana,
  agriBananaPaisa,
  agriBananaProductionMuri,
  agriBananaProductionPathi,
  agriBananaSaleMuri,
  agriBananaSalePathi,
  agriBananaSaleQuantity,
  agriBananaProductionKg,
  agriBananaSaleKg,
  agriOrangeRopani,
  agriOrangeAana,
  agriOrangePaisa,
  agriOrangeProductionMuri,
  agriOrangeProductionPathi,
  agriOrangeSaleMuri,
  agriOrangeSalePathi,
  agriOrangeSaleQuantity,
  agriOrangeProductionKg,
  agriOrangeSaleKg,

  agriGuvaRopani,
  agriGuvaAana,
  agriGuvaPaisa,
  agriGuvaProductionMuri,
  agriGuvaProductionPathi,
  agriGuvaSaleMuri,
  agriGuvaSalePathi,
  agriGuvaSaleQuantity,

  agriAppleRopani,
  agriAppleAana,
  agriApplePaisa,
  agriAppleProductionMuri,
  agriAppleProductionPathi,
  agriAppleSaleMuri,
  agriAppleSalePathi,
  agriAppleSaleQuantity,
  agriAppleProductionKg,
  agriAppleSaleKg,
  agriKiwiRopani,
  agriKiwiAana,
  agriKiwiPaisa,
  agriKiwiProductionMuri,
  agriKiwiProductionPathi,
  agriKiwiSaleMuri,
  agriKiwiSalePathi,
  agriKiwiSaleQuantity,
  agriKiwiProductionKg,
  agriKiwiSaleKg,
  agriNaspatiRopani,
  agriNaspatiAana,
  agriNaspatiPaisa,
  agriNaspatiProductionMuri,
  agriNaspatiProductionPathi,
  agriNaspatiSaleMuri,
  agriNaspatiSalePathi,
  agriNaspatiSaleQuantity,
  agriAvogadoRopani,
  agriAvogadoAana,
  agriAvogadoPaisa,
  agriAvogadoProductionMuri,
  agriAvogadoProductionPathi,
  agriAvogadoSaleMuri,
  agriAvogadoSalePathi,
  agriAvogadoSaleQuantity,
  agriAvogadoProductionKg,
  agriAvogadoSaleKg,

  agriAlaichiRopani,
  agriAlaichiAana,
  agriAlaichiPaisa,
  agriAlaichiProductionMuri,
  agriAlaichiProductionPathi,
  agriAlaichiSaleMuri,
  agriAlaichiSalePathi,
  agriAlaichiProductionKg,
  agriAlaichiSaleKg,

  agriAmrisoRopani,
  agriAmrisoAana,
  agriAmrisoPaisa,
  agriAmrisoProductionMuri,
  agriAmrisoProductionPathi,
  agriAmrisoSaleMuri,
  agriAmrisoSalePathi,
  agriAmrisoProductionKg,
  agriAmrisoSaleKg,

  agriCoffeeProductionKg,
  agriCoffeeSaleKg,

  agriTeaRopani,
  agriTeaAana,
  agriTeaPaisa,
  agriTeaProductionKg,
  agriTeaSaleKg,

  agriGingerRopani,
  agriGingerAana,
  agriGingerPaisa,
  agriGingerProductionMuri,
  agriGingerProductionPathi,
  agriGingerSaleMuri,
  agriGingerSalePathi,
  agriGingerProductionKg,
  agriGingerSaleKg,
  agriBessarRopani,
  agriBessarAana,
  agriBessarPaisa,
  agriBessarProductionMuri,
  agriBessarProductionPathi,
  agriBessarSaleMuri,
  agriBessarSalePathi,
  agriGarlicRopani,
  agriGarlicAana,
  agriGarlicPaisa,
  agriGarlicProductionMuri,
  agriGarlicProductionPathi,
  agriGarlicSaleMuri,
  agriGarlicSalePathi,
  agriGarlicProductionKg,
  agriGarlicSaleKg,
  agriOnionRopani,
  agriOnionAana,
  agriOnionPaisa,
  agriOnionProductionMuri,
  agriOnionProductionPathi,
  agriOnionSaleMuri,
  agriOnionSalePathi,
  agriMushroomRopani,
  agriMushroomAana,
  agriMushroomPaisa,
  agriMushroomProductionMuri,
  agriMushroomProductionPathi,
  agriMushroomSaleMuri,
  agriMushroomSalePathi,
  agriMushroomProductionKg,
  agriMushroomSaleKg,

  agriSugarcaneRopani,
  agriSugarcaneAana,
  agriSugarcanePaisa,
  agriSugarcaneProductionMuri,
  agriSugarcaneProductionPathi,
  agriSugarcaneSaleMuri,
  agriSugarcaneSalePathi,
  agriSugarcaneProductionKg,
  agriSugarcaneSaleKg,
  agriNutRopani,
  agriNutAana,
  agriNutPaisa,
  agriNutProductionMuri,
  agriNutProductionPathi,
  agriNutSaleMuri,
  agriNutSalePathi,
  agriNutProductionKg,
  agriNutSaleKg,
  agriChillyRopani,
  agriChillyAana,
  agriChillyPaisa,
  agriChillyProductionMuri,
  agriChillyProductionPathi,
  agriChillySaleMuri,
  agriChillySalePathi,
  agriChillyProductionKg,
  agriChillySaleKg,
  agriFlowerRopani,
  agriFlowerAana,
  agriFlowerPaisa,
  agriFlowerProductionKg,
  agriFlowerSaleKg,

  agribeeFishSilkSaleQuantity,

  agriOrganicFertilizer,
  agriChemicalFertilizer,
  agrBothFertilizer
};
