import { Grid, Typography } from "@material-ui/core";
import Axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { getCount } from "../../../actions/analysisAction";
import {
	gaupalikaWard,
	selectedLanguage,
	wadaBibaranName,
	ward
} from "../../../variable/global";
import Can from "../../casl/Can";
import PopulationInfo from "./PopulationInfo";
import WardInfo from "./WardInfo";
class Home extends Component {
	constructor(props) {
		super(props);

		const role = parseInt(sessionStorage.getItem("role") || 5);
		const ward = parseInt(sessionStorage.getItem("ward") || 1);
		console.log("log_phidim", role, ward);
		var wardData;
		if (ward === 0) wardData = {};
		else wardData = { wardNumber: ward };
		this.state = {
			wardData,
			role,
			ward,
			cummulative: false,
			cummulativeLoading: true,
			expanded: false,
			progress: [],
			loading: false,
			loadMore: false
		};
	}

	componentDidMount() {
		this.props.getCount(this.state.ward);

		this.getProgressData({}, "few", "loading");
	}

	getProgressData = (wardData, option, parameter) => {
		// parameter is loadmore true ho ki loading
		this.setState({
			[parameter]: true
		});
		const ward = wardData.wardNumber ? wardData.wardNumber : this.state.ward;

		Axios.get(`/api/analysis/population/dashboard/${option}/${ward}`, wardData)
			.then(res => {
				this.setState(prevState => {
					return {
						progress: [...prevState.progress, ...res.data],
						[parameter]: false
					};
				});
			})
			.catch(err => {
				console.error(err);
			});
	};

	getCummulativePopulation = () => {
		Axios.get("/api/cummulative/all-ward-data").then(res => {
			this.setState({ cummulative: res.data, cummulativeLoading: false });
		});
	};

	isCummulativeFetched = () => Boolean(this.state.cummulative);

	onWardClick = wardData => {
		this.state.wardData.wardNumber != wardData.wardNumber &&
			this.setState({ wardData, expanded: false, progress: [] }, () => {
				this.props.getCount(
					wardData.wardNumber ? wardData.wardNumber : this.state.ward
				);
				this.getProgressData(wardData, "few", "loading");
			});
	};

	handleExpanded = (event, expanded) => {
		if (this.state.progress && this.state.progress.length <= 3 && expanded) {
			this.getProgressData(this.state.wardData, "more", "loadMore");
		}
		this.setState({
			expanded
		});
	};

	render() {
		const { population, institution } = this.props;
		const { loading, loadMore } = this.state;
		const gaupalika = gaupalikaWard[selectedLanguage].split(" ");
		return (
			<div style={{ marginBottom: 50 }}>
				<Can do="show" on={{ __type: "Home", ward: this.state.ward }}>
					<Typography variant="h5" gutterBottom>
						{wadaBibaranName[selectedLanguage]}
					</Typography>
					<div style={{ marginBottom: 25, marginTop: 20 }}>
						<Grid container spacing={2}>
							{ward.map((each, key) => (
								<Grid item>
									<WardInfo
										ward={this.state.ward}
										role={this.state.role}
										isActive={key + 1 == this.state.wardData.wardNumber}
										backgroundColor={each.backgroundColor}
										onWardClick={this.onWardClick}
										unique={key + 1}
										key={key}
										wardNumber={each[selectedLanguage]}
										wardName={each.ward}
									/>
								</Grid>
							))}
							<Grid item>
								<WardInfo
									ward={this.state.ward}
									role={this.state.role}
									isActive={undefined == this.state.wardData.wardNumber}
									backgroundColor={"#00ACC1"}
									unique={0}
									wardNumber={gaupalika[0]}
									wardName={gaupalika[1]}
									onWardClick={this.onWardClick}
								/>
							</Grid>
						</Grid>
					</div>
				</Can>

				<PopulationInfo
					expanded={this.state.expanded}
					handleExpanded={this.handleExpanded}
					getCummulativePopulation={this.getCummulativePopulation}
					isCummulativeFetched={this.isCummulativeFetched}
					cummulativeLoading={this.state.cummulativeLoading}
					cummulative={this.state.cummulative}
					progress={this.state.progress}
					wardData={this.state.wardData}
					ispalika={ward === 0}
					population={population}
					loading={this.props.loading}
					progressLoad={loading}
					loadMore={loadMore}
				/>

				{/* <div
					style={{
						// marginTop: "40px",
						marginBottom: "10px",
						float: "left",
						padding: "15px",
						marginRight: "15px",
						borderRadius: "3px",
						backgroundColor: "#999",
						color: "white",
						minWidth: "208px",
						background: "linear-gradient(60deg, #66bb6a, #43a047)"
					}}
				>
					नक्शा
				</div>
				<VillageMap
					GeoJSONData={gaupalikaByward}
					zoom={12}
					position={[27.0937722, 86.6203392]}
					// lat={lat}
					// lng={lng}
					// addMarker={this.props.addMarker}
				>
					<p>
						स्थान प्राप्त गर्न<em>मार्कर</em> पिन गर्नुहोस
					</p>
				</VillageMap> */}
				{/* <InstitutionalInfo loading={loading} /> */}
			</div>
		);
	}
}

const mapStateToProps = ({ analysis }) => ({
	population: analysis.populationCount,
	institution: analysis.institutionCount,
	loading: analysis.loading
});

export default connect(mapStateToProps, { getCount })(Home);
