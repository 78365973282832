import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import getNepalNumber from "../../../../../utils/getNepaliNumber";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { no, selectedLanguage, yes } from "../../../../../variable/global";
import {
  QuestionAnswer,
  Title
} from "../../../../common/frequent/QuestionAnswer";
import {
  welfare,
  welfareType,
  welfareMale,
  welfareFemale,
  bhattaYesNo
} from "../../../../../variable/houseSurvey";

function mapValue(value, obj) {
  return obj[value];
}

class SearchByHouseNumber extends Component {
  getYesNo = data => (data ? yes[selectedLanguage] : no[selectedLanguage]);
  render() {
    const { classes, bhatta } = this.props;
    return (
      <Grid item container direction="column" className={classes.container}>
        <Title title={welfare} />

        <Grid item container>
          {bhatta.anyBhatta ? (
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>{welfareType[selectedLanguage]}</TableCell>
                  <TableCell align="right">
                    {welfareMale[selectedLanguage]}
                  </TableCell>
                  <TableCell align="right">
                    {welfareFemale[selectedLanguage]}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {bhatta.detail.map((row, key) => (
                  <TableRow key={key}>
                    <TableCell scope="row">
                      {mapValue(row.title, {
                        old_citizen: "ज्येष्ठ नागरिक",
                        single_women: "एकल महिला",
                        full_disable: "पूर्ण अपाङ्ग",
                        half_disable: "आंसिक अपाङ्ग",
                        child_security: "बाल सुरक्षा अनुदान",
                        dalit: "दलित वर्ग",
                        endangered: "लोपउन्मुख जाति"
                      })}
                    </TableCell>
                    <TableCell align="right">
                      {getNepalNumber(row.maleCount)}
                    </TableCell>
                    <TableCell align="right">
                      {getNepalNumber(row.femaleCount)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <QuestionAnswer
              question={bhattaYesNo[selectedLanguage]}
              answer={this.getYesNo(bhatta.anyBhatta)}
            />
          )}
        </Grid>
      </Grid>
    );
  }
}

const styles = theme => ({
  table: {
    width: "auto"
  },
  container: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 2
  }
});

SearchByHouseNumber.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SearchByHouseNumber);
