import React, { Component } from "react";
import Axios from "axios";
import { Grid } from "@material-ui/core";
import getNepaliNumber from "../../../utils/getNepaliNumber";
import { selectedLanguage } from "../../../variable/global";
import { Divider, Table } from "antd";
const isNumber = value =>
	typeof value === "number" && value === value && Number.isFinite(value);
const style = {
	p: {
		margin: 0,
		padding: 0,
		fontWeight: "800"
	}
};
export default class PreviewForm extends Component {
	state = {
		data: {},
		mapValue: {},
		mapLabel: {}
	};
	componentDidMount() {
		const { id, category } = this.props.match.params;
		Axios.get(`/api/institute/${category}/read/${id}`).then(res => {
			this.setState({
				data: res.data.data,
				mapValue: res.data.mapValue,
				mapLabel: res.data.mapLabel
			});
		});
	}

	pageNotFound = () => <p>Not found Id</p>;
	render() {
		const { data, mapValue, mapLabel } = this.state;
		const keys = Object.keys(data);
		if (!data) return this.pageNotFound();
		return (
			<Grid container>
				{/* Title Here */}
				{/* image Here */}
				<Grid container spacing={1}>
					{keys.map(key => this.renderGeneric(data, key))}
				</Grid>
			</Grid>
		);
	}
	renderGeneric = (on, key) => {
		const value = on[key];
		if (value === undefined || value === null) return null;
		if (Array.isArray(value) && value.length > 0) {
			if (typeof value[0] === "string")
				return this.renderCommaSeparated(key, value);
			else if (typeof value[0] === "object")
				return this.renderTable(value, key);
			else return null;
		} else if (typeof value === "object") {
			const { mapLabel } = this.state;
			if (mapLabel[key]) return this.renderBoxObject(value, key); //if key is exist in mapLabel then render the box else just recursive
			return Object.keys(value).map(k => this.renderGeneric(value, k));
		} else if (typeof value === "boolean") {
			return this.renderBoolean(key, value);
		} else if (typeof value === "string" || isNumber(value)) {
			return this.renderKey(key, value);
		} else return null;
	};

	notPrintIF = key => {
		return key === "_id" || !key;
	};
	renderTable = (on, key) => {
		const { mapLabel } = this.state;
		const keys = Object.keys(on[0]);
		var columns = [];
		keys.forEach(each => {
			if (this.notPrintIF(each)) return;
			else
				columns.push({
					title: (
						<p style={{ fontSize: 14, fontWeight: 600 }}>
							{mapLabel[each] || each}
						</p>
					),
					dataIndex: each,
					key: each,
					render: text => <p>{text}</p>
				});
		});
		var data = [];
		on.forEach(each => {
			if (this.notPrintIF(each)) return;
			let body = {};
			keys.forEach(one => (body[one] = this.getValue(one, each[one])));
			data.push(body);
		});
		console.log("data", data);
		return (
			<Grid container style={{ margin: "20px 0px" }}>
				<Table
					style={{ width: "100%" }}
					columns={columns}
					dataSource={data}
					bordered
					title={() => (
						<p style={{ fontSize: 17, fontWeight: 600 }}>{mapLabel[key]}</p>
					)}
				/>
			</Grid>
		);
	};

	renderBoxObject = (on, key) => {
		const { mapLabel } = this.state;
		return (
			<Grid
				container
				style={{
					padding: 5,
					margin: "5px 0px"
				}}
			>
				<Divider>
					<b style={{ fontSize: 20 }}>{mapLabel[key] || key}</b>
				</Divider>
				<Grid container>
					{Object.keys(on).map(k => this.renderGeneric(on, k))}
				</Grid>
				<Divider>X</Divider>
			</Grid>
		);
	};

	renderHeading = (text, extraText = "") => {
		const { mapLabel } = this.state;
		return <p style={style.p}>{(mapLabel[text] || text) + extraText} </p>;
	};
	getValue = (key, value) => {
		const { mapValue } = this.state;
		var text = value || "N/A";
		if (mapValue[key]) {
			const foundOne = mapValue[key].find(each => each.value == value);
			if (foundOne) text = foundOne[selectedLanguage];
		}
		return text;
	};
	renderValue = (key, value) => {
		return (
			<p style={{ marginLeft: 5 }}>
				{getNepaliNumber(this.getValue(key, value))}
			</p>
		);
	};
	renderCommaSeparated = (key, value) => {
		return (
			<Grid item xs={12} md={12} lg={12} style={{ display: "flex" }}>
				{this.renderHeading(key, ":")}
				<p style={{ marginLeft: 5 }}>
					{value.map(each => this.getValue(key, each)).join(", ")}
				</p>
			</Grid>
		);
	};

	renderKey = (key, value) => {
		return (
			<Grid item xs={12} md={6} lg={4} style={{ display: "flex" }}>
				{this.renderHeading(key, ":")} {this.renderValue(key, value)}
			</Grid>
		);
	};

	renderBoolean = (key, value) => {
		return (
			<Grid item xs={12} md={6} lg={4} style={{ display: "flex" }}>
				{this.renderHeading(key, ": ")} <p>{value ? " छ" : " छैन"}</p>
			</Grid>
		);
	};

	renderArray = () => {
		const { data, mapValue, mapLabel } = this.state;
	};

	renderObject = () => {
		const { data, mapValue, mapLabel } = this.state;
	};
}
