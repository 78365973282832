import { AbilityBuilder } from "@casl/ability";

/**
 * Defines how to detect object's type: https://stalniy.github.io/casl/abilities/2017/07/20/define-abilities.html
 */
function subjectName(item) {
	if (!item || typeof item === "string") {
		return item;
	}

	return item.__type;
}

export default AbilityBuilder.define({ subjectName }, (can, cannot) => {
	// home
	can(["show"], "Home", { ward: 0 });
	cannot(["delete"], "User", { user: "me" });
	//users
	can(["delete", "edit"], "User", {
		canDelete: true,
	});
	can(["add"], "User", {
		$or: [{ role: 0 }, { role: 1 }],
	});
});
