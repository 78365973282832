import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Button } from "antd";
import classNames from "classnames";
import React, { Component } from "react";

class WardInfo extends Component {
  onWardClick = unique => evt => {
    let data = {};
    if (unique !== 0) data = { wardNumber: unique };
    this.props.onWardClick(data);
  };
  render() {
    const {
      classes,

      wardNumber,
      wardName,
      unique,
      isActive
    } = this.props;

    return (
      <Button
        type={isActive ? "primary" : null}
        shape="round"
        size="large"
        className={classNames(classes.button, isActive && classes.activeButton)}
        onClick={this.onWardClick(unique)}
      >
        <span style={{ display: "flex", verticalAlign: "baseline" }}>
          <Typography
            variant="body2"
            className={classNames(
              classes.wardNumber,
              isActive && classes.white
            )}
          >
            {wardName}
          </Typography>
        </span>
      </Button>
    );
  }
}

const styles = theme => ({
  wardText: {
    color: "#FF0000"
  },
  white: {
    color: "#FFFFFF"
  },

  container: {
    display: "inline-flex"
  },
  button: {
    height: "auto",
    padding: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3
  },
  activeButton: {
    backgroundColor: "#2196F3",
    color: "#FFF"
  },
  span: { display: "flex", verticalAlign: "baseline" },
  wardNumber: { marginRight: 10 }
});

export default withStyles(styles)(WardInfo);
