import { START_LOADER, STOP_LOADER, START_SNACK, CLOSE_SNACK } from "./types";
export const startLoader = () => {
  return {
    type: START_LOADER
  };
};

export const stopLoader = () => ({
  type: STOP_LOADER
});

export const startSnack = value => {
  return {
    type: START_SNACK,
    payload: value
  };
};

export const startSnackBar = value => dispatch => {
  dispatch(startSnack(value));
};

export const closeSnack = () => ({
  type: CLOSE_SNACK
});

export const onCloseSnackBar = () => dispatch => {
  dispatch(closeSnack());
};
