import { Grid, Collapse, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { geoCode, selectedLanguage } from "../../../../variable/global";
import ImagesUploader from "react-images-uploader";
import {
  aadibasi,
  aadibasiQuestion,
  abroadCountry,
  abroadCountryQuestion,
  abroadReason,
  abroadReasonQuestion,
  abroadYearQuestion,
  answererRelation,
  answererRelationQuestion,
  bloodgroup,
  bloodgroupQuestion,
  casteList,
  casteQuestion,
  contactNo,
  education,
  educationQuestion,
  gender,
  genderQuestion,
  healthcondition,
  healthconditionQuesiton,
  industry,
  industryQuestion,
  job,
  jobQuestion,
  livingStatus,
  livingStatusQuestion,
  marriage,
  marriageQuestion,
  memberName,
  mentalCardQuestion,
  mentalCardtype,
  mentalCardTypeQuestion,
  mentalCardYesNo,
  mentalcondition,
  mentalconditionQuestion,
  mentaltype,
  mentaltypeQuestion,
  mentalyesno,
  mentalYesNoQuestion,
  occupation,
  occupationQuestion,
  pahadi,
  pahadiQuestion,
  preprimary,
  preprimaryQuestion,
  primary,
  primaryQuestion,
  raiQuestion,
  raiType,
  religionList,
  religionQuestion,
  secondary,
  secondaryQuestion,
  security,
  securityQuestion,
  teacher,
  teacherQuestion,
  unhealthy,
  unhealthyQuestion,
  insuranceYesNo,
  insuranceQuestion,
  insuranceListQuestion,
  insuranceLists,
  votercard,
  votercardQuestion,
  mothertongueQuestion,
  mothertongue,
  familyFemale,
  familyMale,
  totalMember,
  familyThirdGender,
  citizenshipFrontPhoto,
  citizenshipBackPhoto,
  ageQuestion,
  dateQuestion,
  skillTrainingQuestion,
  skillTraining,
  skillDetailQuestion,
  skillDetail,
} from "../../../../variable/houseSurvey";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandIcon from "@material-ui/icons/ExpandMore";
import {
  Chip,
  ExpansionPanel,
  withStyles,
  IconButton,
} from "@material-ui/core";
import BoxBorder from "../../../common/frequent/BoxBorder";
import MultipleSelect from "../../../common/frequent/MultipleSelect";
import RadioButton from "../../../common/frequent/RadioButton";
import TextField from "../../../common/frequent/TextField";
import DeleteIcon from "@material-ui/icons/Delete";
import Selection from "../../../common/frequent/Selection";
import TextField1 from "@material-ui/core/TextField";
import { Upload, Icon, Modal } from "antd";
import CheckBox from "../../../common/frequent/CheckBox";
class FamilyUsage extends Component {
  state = {
    index: -1,
    expanded: "family0",
    previewVisible: false,
    previewImage: "",
  };

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = (file) => {
    this.setState({
      previewImage: file.url || file.thumbUrl,
      previewVisible: true,
    });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleChange = (name, value) => {
    this.props.handleChange(`familyDetail.${name}`, value);
  };

  resetThisForm = (index) => () => {
    const { familyDetail } = this.props;
    const total = parseInt(familyDetail.totalMember);
    if (total > 0) this.props.handleResetFamily(index);
  };

  handleExpansion = (panel) => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };
  getFamilyForm = () => {
    const { familyDetail, classes } = this.props;
    const total = parseInt(familyDetail.totalMember);

    const errors = this.props.errors && this.props.errors.errors;

    if (!isNaN(total) && total > 0) {
      return (
        <Grid container direction="column">
          {[...Array(total).keys()].map((each) => {
            const thisError = errors && errors.family && errors.family[each];
            const error = thisError && !thisError.isOk;
            return (
              <ExpansionPanel
                key={each}
                style={{ padding: 0, backgroundColor: "transparent" }}
                expanded={this.state.expanded === `family${each}`}
                onChange={this.handleExpansion(`family${each}`)}
              >
                <ExpansionPanelSummary
                  className={classes.summary}
                  expandIcon={<ExpandIcon className={classes.white} />}
                >
                  <Grid
                    container
                    className={error ? classes.headerError : classes.header}
                  >
                    {familyDetail[`fullName${each}`]}
                  </Grid>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className={classes.details}>
                  <Collapse in={true}>
                    <Grid
                      container
                      direction="column"
                      className={classes.eachDeath}
                    >
                      <Grid xs={6}>
                        <TextField
                          error={thisError && thisError.fullName}
                          question={memberName}
                          name={`fullName${each}`}
                          require
                          isVisible
                          placeholder=""
                          value={familyDetail[`fullName${each}`]}
                          handleChange={this.handleChange}
                        />
                      </Grid>
                      <Grid xs={6}>
                        <Selection
                          error={thisError && thisError.answererRelation}
                          question={answererRelationQuestion}
                          name={`answererRelation${each}`}
                          selectList={answererRelation}
                          value={familyDetail[`answererRelation${each}`]}
                          isVisible
                          handleChange={this.handleChange}
                        />
                      </Grid>
                      <Grid xs={6}>
                        <Typography variant="subheading" gutterBottom>
                          {dateQuestion[selectedLanguage]}
                        </Typography>
                        <TextField1
                          name={`dateOfBirth${each}`}
                          isVisible
                          placeholder="YY/MM/DD"
                          value={familyDetail[`dateOfBirth${each}`]}
                          handleChange={this.handleChange}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid xs={6}>
                        <TextField
                          type="number"
                          question={ageQuestion}
                          name={`age${each}`}
                          require
                          isVisible
                          placeholder=""
                          value={familyDetail[`age${each}`]}
                          handleChange={this.handleChange}
                        />
                      </Grid>
                      <Grid xs={6}>
                        <TextField
                          error={thisError && thisError.contactNumber}
                          type="number"
                          question={contactNo}
                          name={`contactNumber${each}`}
                          require
                          isVisible
                          placeholder=""
                          value={familyDetail[`contactNumber${each}`]}
                          handleChange={this.handleChange}
                        />
                      </Grid>
                      <Grid>
                        <RadioButton
                          error={thisError && thisError.gender}
                          handleChange={this.handleChange}
                          isVisible
                          question={genderQuestion}
                          name={`gender${each}`}
                          radioLists={gender}
                          value={familyDetail[`gender${each}`]}
                        />
                      </Grid>
                      <Grid>
                        <RadioButton
                          error={thisError && thisError.livingStatus}
                          handleChange={this.handleChange}
                          isVisible
                          question={livingStatusQuestion}
                          name={`livingStatus${each}`}
                          radioLists={livingStatus}
                          value={familyDetail[`livingStatus${each}`]}
                        />
                      </Grid>
                      <BoxBorder
                        isVisible={
                          familyDetail[`livingStatus${each}`] === "foreign"
                        }
                      >
                        <RadioButton
                          error={thisError && thisError.abroadReason}
                          handleChange={this.handleChange}
                          isVisible
                          question={abroadReasonQuestion}
                          name={`abroadReason${each}`}
                          radioLists={abroadReason}
                          value={familyDetail[`abroadReason${each}`]}
                          other={familyDetail[`abroadReason${each}Other`]}
                        />
                        <TextField
                          error={thisError && thisError.bideshiyekoYear}
                          question={abroadYearQuestion}
                          name={`bideshiyekoYear${each}`}
                          require
                          isVisible
                          placeholder=""
                          value={familyDetail[`bideshiyekoYear${each}`]}
                          handleChange={this.handleChange}
                        />
                        <RadioButton
                          error={thisError && thisError.abroadCountry}
                          handleChange={this.handleChange}
                          isVisible
                          question={abroadCountryQuestion}
                          name={`abroadCountry${each}`}
                          radioLists={abroadCountry}
                          value={familyDetail[`abroadCountry${each}`]}
                          other={familyDetail[`abroadCountryOther${each}`]}
                        />
                      </BoxBorder>
                      <Grid xs={6}>
                        <Selection
                          error={thisError && thisError.casteList}
                          question={casteQuestion}
                          name={`casteList${each}`}
                          selectList={casteList}
                          value={familyDetail[`casteList${each}`]}
                          isVisible
                          handleChange={this.handleChange}
                          other={familyDetail[`casteListOther${each}`]}
                        />
                      </Grid>
                      <Grid>
                        <RadioButton
                          error={thisError && thisError.aadibasi}
                          handleChange={this.handleChange}
                          isVisible={
                            familyDetail[`casteList${each}`] ===
                            "janjaati_aadiw"
                          }
                          question={aadibasiQuestion}
                          name={`aadibasi${each}`}
                          radioLists={aadibasi}
                          value={familyDetail[`addiwasCaste${each}`]}
                          other={familyDetail[`addiwasCasteOther${each}`]}
                        />
                      </Grid>
                      <Grid>
                        <RadioButton
                          error={thisError && thisError.religionList}
                          handleChange={this.handleChange}
                          isVisible
                          question={religionQuestion}
                          name={`religionList${each}`}
                          radioLists={religionList}
                          value={familyDetail[`religionList${each}`]}
                          other={familyDetail[`religionListOther${each}`]}
                        />
                      </Grid>
                      <Grid xs={6}>
                        <Selection
                          error={thisError && thisError.marriage}
                          question={marriageQuestion}
                          name={`marriage${each}`}
                          selectList={marriage}
                          value={familyDetail[`marriage${each}`]}
                          isVisible
                          handleChange={this.handleChange}
                        />
                      </Grid>
                      <Grid xs={6}>
                        <Selection
                          error={thisError && thisError.education}
                          question={educationQuestion}
                          name={`education${each}`}
                          selectList={education}
                          value={familyDetail[`education${each}`]}
                          isVisible
                          handleChange={this.handleChange}
                        />
                      </Grid>
                      <Grid>
                        <RadioButton
                          error={thisError && thisError.primary}
                          handleChange={this.handleChange}
                          isVisible={
                            familyDetail[`education${each}`] === "primary"
                          }
                          question={primaryQuestion}
                          name={`primary${each}`}
                          radioLists={primary}
                          value={familyDetail[`primary${each}`]}
                        />
                      </Grid>
                      <Grid>
                        <RadioButton
                          error={thisError && thisError.secondary}
                          handleChange={this.handleChange}
                          isVisible={
                            familyDetail[`education${each}`] === "secondary"
                          }
                          question={secondaryQuestion}
                          name={`secondary${each}`}
                          radioLists={secondary}
                          value={familyDetail[`secondary${each}`]}
                        />
                      </Grid>
                      <Grid xs={6}>
                        <MultipleSelect
                          error={thisError && thisError.occupation}
                          question={occupationQuestion}
                          name={`occupation${each}`}
                          value={familyDetail[`occupation${each}`]}
                          tagLists={occupation}
                          isVisible
                          handleChange={this.handleChange}
                          other={familyDetail[`occupationOther${each}`]}
                        />
                      </Grid>
                      <Grid>
                        <RadioButton
                          error={thisError && thisError.job}
                          handleChange={this.handleChange}
                          isVisible={
                            familyDetail[`occupation${each}`] === "job"
                          }
                          question={jobQuestion}
                          name={`job${each}`}
                          radioLists={job}
                          value={familyDetail[`job${each}`]}
                          other={familyDetail[`jobOther${each}`]}
                        />
                      </Grid>
                      {/* <Grid>
												<RadioButton
													error={thisError && thisError.security}
													handleChange={this.handleChange}
													isVisible={familyDetail[`job${each}`] === "security"}
													question={securityQuestion}
													name={`security${each}`}
													radioLists={security}
													value={familyDetail[`security${each}`]}
													other={familyDetail[`securityOther${each}`]}
												/>
											</Grid>
											<Grid>
												<RadioButton
													error={thisError && thisError.teacher}
													handleChange={this.handleChange}
													isVisible={familyDetail[`job${each}`] === "teacher"}
													question={teacherQuestion}
													name={`teacher${each}`}
													radioLists={teacher}
													value={familyDetail[`teacher${each}`]}
												/>
											</Grid> */}
                      <Grid>
                        <RadioButton
                          error={thisError && thisError.industry}
                          handleChange={this.handleChange}
                          isVisible={
                            familyDetail[`occupation${each}`] ===
                            "bussiness_work"
                          }
                          question={industryQuestion}
                          name={`industry${each}`}
                          radioLists={industry}
                          value={familyDetail[`industry${each}`]}
                          other={familyDetail[`industryOther${each}`]}
                        />
                      </Grid>
                      <BoxBorder
                        isVisible={
                          familyDetail[`livingStatus${each}`] ===
                            "same_place" ||
                          familyDetail[`livingStatus${each}`] === "other_place"
                        }
                      >
                        <Grid>
                          <RadioButton
                            error={thisError && thisError.healthcondition$}
                            handleChange={this.handleChange}
                            isVisible
                            question={healthconditionQuesiton}
                            name={`healthcondition${each}`}
                            radioLists={healthcondition}
                            value={familyDetail[`healthcondition${each}`]}
                            // other ={familyDetail[`casteListOther${each}`]}
                          />
                        </Grid>
                        <Grid xs={6}>
                          <MultipleSelect
                            error={thisError && thisError.unhealthy}
                            question={unhealthyQuestion}
                            name={`unhealthy${each}`}
                            value={familyDetail[`unhealthy${each}`]}
                            tagLists={unhealthy}
                            isVisible={
                              familyDetail[`healthcondition${each}`] ===
                              "unhelathy"
                            }
                            handleChange={this.handleChange}
                            other={familyDetail[`unhealthyOther${each}`]}
                          />
                        </Grid>
                        <Grid>
                          <RadioButton
                            error={thisError && thisError.mentalyesno}
                            handleChange={this.handleChange}
                            isVisible
                            question={mentalYesNoQuestion}
                            name={`mentalyesno${each}`}
                            radioLists={mentalyesno}
                            value={familyDetail[`mentalyesno${each}`]}
                            // other ={familyDetail[`casteListOther${each}`]}
                          />
                        </Grid>
                        <BoxBorder
                          isVisible={
                            familyDetail[`mentalyesno${each}`] === "disability"
                          }
                        >
                          <RadioButton
                            error={thisError && thisError.mentaltype}
                            handleChange={this.handleChange}
                            isVisible
                            question={mentaltypeQuestion}
                            name={`mentaltype${each}`}
                            radioLists={mentaltype}
                            value={familyDetail[`mentaltype${each}`]}
                          />
                          <RadioButton
                            error={thisError && thisError.mentalcondition}
                            handleChange={this.handleChange}
                            isVisible
                            question={mentalconditionQuestion}
                            name={`mentalcondition${each}`}
                            radioLists={mentalcondition}
                            value={familyDetail[`mentalcondition${each}`]}
                            other={familyDetail[`mentalconditionOther${each}`]}
                          />
                          <Grid container>
                            <RadioButton
                              error={thisError && thisError.mentalCardYesNo}
                              handleChange={this.handleChange}
                              isVisible
                              question={mentalCardQuestion}
                              name={`mentalCardYesNo${each}`}
                              radioLists={mentalCardYesNo}
                              value={familyDetail[`mentalCardYesNo${each}`]}
                            />
                          </Grid>
                          <Grid container>
                            <RadioButton
                              error={thisError && thisError.mentalCardtype}
                              handleChange={this.handleChange}
                              isVisible={
                                familyDetail[`mentalCardYesNo${each}`] ===
                                "card_taken"
                              }
                              question={mentalCardTypeQuestion}
                              name={`mentalCardtype${each}`}
                              radioLists={mentalCardtype}
                              value={familyDetail[`mentalCardtype${each}`]}
                            />
                          </Grid>
                        </BoxBorder>
                        <Grid container>
                          <RadioButton
                            error={thisError && thisError.insuranceYesNo}
                            handleChange={this.handleChange}
                            isVisible
                            question={insuranceQuestion}
                            name={`insuranceYesNo${each}`}
                            radioLists={insuranceYesNo}
                            value={familyDetail[`insuranceYesNo${each}`]}
                          />
                        </Grid>
                        <BoxBorder
                          isVisible={
                            familyDetail[`insuranceYesNo${each}`] ===
                            "insurance"
                          }
                        >
                          <RadioButton
                            error={thisError && thisError.insuranceLists}
                            handleChange={this.handleChange}
                            isVisible
                            question={insuranceListQuestion}
                            name={`insuranceLists${each}`}
                            radioLists={insuranceLists}
                            value={familyDetail[`insuranceLists${each}`]}
                          />
                        </BoxBorder>
                      </BoxBorder>
                      <Grid container>
                        <Selection
                          error={thisError && thisError.mothertongue}
                          question={mothertongueQuestion}
                          name={`mothertongue${each}`}
                          selectList={mothertongue}
                          value={familyDetail[`mothertongue${each}`]}
                          isVisible
                          handleChange={this.handleChange}
                          other={familyDetail[`mothertongueOther${each}`]}
                        />
                      </Grid>

                      <Grid container>
                        <RadioButton
                          error={thisError && thisError.raiType}
                          handleChange={this.handleChange}
                          isVisible={
                            familyDetail[`mothertongue${each}`] === "rai"
                          }
                          question={raiQuestion}
                          name={`raiType${each}`}
                          radioLists={raiType}
                          value={familyDetail[`raiType${each}`]}
                        />
                      </Grid>
                      <Grid container>
                        <RadioButton
                          error={thisError && thisError.bloodgroup}
                          handleChange={this.handleChange}
                          isVisible
                          question={bloodgroupQuestion}
                          name={`bloodgroup${each}`}
                          radioLists={bloodgroup}
                          value={familyDetail[`bloodgroup${each}`]}
                          other={familyDetail[`bloodgroupOther${each}`]}
                        />
                      </Grid>
                      <Grid container>
                        <MultipleSelect
                          error={thisError && thisError.votercard}
                          question={votercardQuestion}
                          name={`votercard${each}`}
                          value={familyDetail[`votercard${each}`]}
                          tagLists={votercard}
                          isVisible
                          handleChange={this.handleChange}
                        />
                      </Grid>
                      <Grid container>
                        <RadioButton
                          handleChange={this.handleChange}
                          isVisible
                          question={skillTrainingQuestion}
                          radioLists={skillTraining}
                          name={`skillTraining${each}`}
                          value={familyDetail[`skillTraining${each}`]}
                        />
                      </Grid>

                      <Grid container>
                        <CheckBox
                          question={skillDetailQuestion}
                          checkList={skillDetail}
                          value={familyDetail[`skillDetail${each}`]}
                          name={`skillDetail${each}`}
                          isVisible={
                            familyDetail[`skillTraining${each}`] &&
                            familyDetail[`skillTraining${each}`].includes(
                              "skill_yes"
                            )
                          }
                          handleCompChange={this.handleChange}
                          other={familyDetail[`skillTrainingOther${each}`]}
                        />
                      </Grid>

                      <Grid
                        container
                        direction="row-reverse"
                        justify="flex-start"
                        alignItems="flex-start"
                      >
                        <Grid item>
                          <IconButton
                            onClick={this.resetThisForm(each)}
                            aria-label="Delete"
                            className={classes.margin}
                          >
                            <DeleteIcon
                              fontSize="large"
                              style={{ color: "red" }}
                            />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Collapse>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            );
          })}
        </Grid>
      );
    }
  };

  render() {
    const { familyDetail, fileList } = this.props;
    const errors = this.props.errors && this.props.errors.errors;
    console.log("familyDetail errors", errors);
    const { previewVisible, previewImage } = this.state;
    const uploadButton = (
      <div>
        <Icon type="plus" />
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    return (
      <Grid container direction="column">
        <BoxBorder isVisible>
          {/* <Grid xs={6}>
						<TextField
							error={errors && errors.familyMale}
							type="number"
							question={familyMale}
							name="familyMale"
							require
							value={familyDetail.familyMale}
							isVisible
							handleChange={this.props.handleMemberNumber}
						/>
					</Grid>
					<Grid xs={6}>
						<TextField
							error={errors && errors.familyFemale}
							type="number"
							question={familyFemale}
							name="familyFemale"
							require
							value={familyDetail.familyFemale}
							isVisible
							handleChange={this.props.handleMemberNumber}
						/>
					</Grid>
					<Grid xs={6}>
						<TextField
							error={errors && errors.familyThirdGender}
							type="number"
							question={familyThirdGender}
							name="familyThirdGender"
							value={familyDetail.familyThirdGender}
							isVisible
							handleChange={this.props.handleMemberNumber}
						/>
					</Grid> */}
          <Grid xs={6}>
            <TextField
              require
              error={errors && errors.totalMember}
              type="number"
              question={totalMember}
              name="totalMember"
              disable
              handleChange={this.handleChange}
              value={familyDetail.totalMember}
              isVisible
            />
          </Grid>

          <Typography variant="h6">नागरिकताको फोटो</Typography>
          {this.props.isHouseNumberValid ? (
            <Grid container direction="row" spacing={24}>
              <Grid item direction="column" spacing={16} justify="center">
                <Grid item>
                  <p style={{ textAlign: "center" }}>अगाडी</p>
                </Grid>

                <Grid item>
                  <Upload
                    name="file"
                    // beforeUpload={this.props.beforeUpload}
                    multiple={false}
                    accept="image/*"
                    listType="picture-card"
                    fileList={
                      fileList.citizen_front && fileList.citizen_front.image
                    }
                    onPreview={this.handlePreview}
                    onChange={(fileList) =>
                      this.props.handleChangeFile(
                        "citizen_front",
                        fileList,
                        "citizen_front"
                      )
                    }
                  >
                    {fileList &&
                    fileList.citizen_front &&
                    fileList.citizen_front.image &&
                    fileList.citizen_front.image.length === 1
                      ? null
                      : uploadButton}
                  </Upload>
                  <Modal
                    visible={previewVisible}
                    footer={null}
                    onCancel={this.handleCancel}
                  >
                    <img
                      alt="example"
                      style={{ width: "100%" }}
                      src={previewImage}
                    />
                  </Modal>
                </Grid>
              </Grid>

              <Grid item direction="column" spacing={16}>
                <Grid item>
                  <p style={{ textAlign: "center" }}>पछाडी</p>
                </Grid>
                <Grid item>
                  <Upload
                    name="file"
                    // beforeUpload={this.props.beforeUpload}
                    multiple={false}
                    // action="/api/imageUpload/upload"
                    accept="image/*"
                    listType="picture-card"
                    fileList={
                      fileList.citizen_back && fileList.citizen_back.image
                    }
                    onPreview={this.handlePreview}
                    onChange={(fileList) =>
                      this.props.handleChangeFile(
                        "citizen_back",
                        fileList,
                        "citizen_back"
                      )
                    }
                  >
                    {fileList &&
                    fileList.citizen_back &&
                    fileList.citizen_back.image &&
                    fileList.citizen_back.image.length === 1
                      ? null
                      : uploadButton}
                  </Upload>
                  <Modal
                    visible={previewVisible}
                    footer={null}
                    onCancel={this.handleCancel}
                  >
                    <img
                      alt="example"
                      style={{ width: "100%" }}
                      src={previewImage}
                    />
                  </Modal>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <h4 stlye={{ marginLeft: 5 }}>
              {
                {
                  english: "please select unique house number in english",
                  nepali: "कृपया अंग्रेजीमा अद्वितीय घर नम्बर चयन गर्नुहोस्",
                }[selectedLanguage]
              }
            </h4>
          )}
        </BoxBorder>

        <Grid container>{this.getFamilyForm()}</Grid>
      </Grid>
    );
  }
}

const styles = (theme) => ({
  header: {
    width: "100%",
    height: 20,
    backgroundColor: "#00A97c",
    borderTopRightRadius: 5,
    borderTopLeftRadius: 5,
    padding: 20,
  },
  headerError: {
    height: 20,
    padding: 20,
    width: "100%",
    borderTopRightRadius: 5,
    borderTopLeftRadius: 5,
    backgroundColor: "#FB3E44",
  },
  container: {
    backgroundColor: "transparent",
    // padding: 0,
    // margin: 0,
    border: "none",
  },
  summary: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  category: {
    // minHeight: 30,
    color: "#FFF",
  },
  details: {
    padding: 0,
    margin: 0,
    flexWrap: "nowrap",
    // border: "none",
    // marginTop: theme.spacing.unit * 1
  },
  noteContainer: {
    // margin: theme.spacing.unit
    flexWrap: "nowrap",
  },
  white: {
    color: "#FFF",
  },
  eachLink: {
    marginTop: 1,
  },
  eachDeath: {
    width: "100%",
    border: "1px solid  rgba(0, 0, 0, 0.19)",
    borderTop: "none !important",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 1}px ${
      theme.spacing.unit * 2
    }px ${theme.spacing.unit * 2}px`,
    borderBottomRightRadius: 5,
    borderBottomLeftRadius: 5,
    marginBottom: 20,
  },
});

FamilyUsage.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(FamilyUsage);
