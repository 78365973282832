import React from "react";
import { withRouter } from "react-router-dom";
import clsx from "clsx";
import { Resizable } from "react-resizable";
import { Table, Divider, Button, Popconfirm, Icon } from "antd";
import { Link } from "react-router-dom";
import "antd/dist/antd.css";

const { Column } = Table;

function ProductsTable({
	rowSelection,
	columns,
	data,
	handleDelete,
	category
}) {
	return (
		<Table
			style={{ width: "100%", margin: "0px 5px" }}
			rowSelection={rowSelection}
			// columns={columns}
			dataSource={data}
		>
			<Column
				title={<span className="font-semibold">क्र.श</span>}
				dataIndex={"sn"}
				key={"sn"}
				render={(text, record, index) => <p>{index + 1}</p>}
			/>

			{columns.map(each => (
				<Column {...each} />
			))}
			<Column
				title={<span className="font-semibold">क्रियाकलाप</span>}
				key="action"
				render={(text, record, each) => {
					return (
						<span>
							<Link
								to={`/survey-by-institution/preview/${category}/${record.id}`}
							>
								<Icon
									className="text-xs"
									type="eye"
									theme="twoTone"
									twoToneColor="#009688"
								/>
							</Link>
							<Divider type="vertical" />
							<Link to={`/survey-by-institution/edit/${category}/${record.id}`}>
								<Icon type="edit" theme="filled" className="text-xs" />
							</Link>
							<Divider type="vertical" />
							<Popconfirm
								title="के तपाई यो डाटा हटाउन चाहनुहुन्छ?"
								onConfirm={e => handleDelete(record.id)}
								okText="Yes"
								cancelText="No"
							>
								<Icon
									className="cursor-pointer text-xs"
									type="delete"
									theme="twoTone"
									twoToneColor="#f44336"
								/>
							</Popconfirm>
						</span>
					);
				}}
			/>
		</Table>
	);
}

const ResizeableTitle = props => {
	const { onResize, width, ...restProps } = props;

	if (!width) {
		return <th {...restProps} />;
	}

	return (
		<Resizable
			width={width}
			height={0}
			onResize={onResize}
			draggableOpts={{ enableUserSelectHack: false }}
		>
			<th {...restProps} />
		</Resizable>
	);
};

export default withRouter(ProductsTable);
