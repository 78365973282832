import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Grid,
} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import ReactToPrint from "../../../common/frequent/print/ReactToPrint";
import Fab from "@material-ui/core/Fab";
import PrintIcon from "@material-ui/icons/Print";
import getNepaliNumber from "../../../../utils/getNepaliNumber";
import React, { Component } from "react";
import Card from "../../../common/Card/Card.jsx";
import CardBody from "../../../common/Card/CardBody.jsx";
import CardHeader from "../../../common/Card/CardHeader.jsx";
import GridContainer from "../../../common/Grid/GridContainer.jsx";
import GridItem from "../../../common/Grid/GridItem.jsx";

class ToleFilterTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      beforePrint: false,
      component: null,
      open: false,
    };
    this.myRef = React.createRef();
  }

  getGender = (gender) => {
    if (gender === "female") return "महिला";
    else if (gender === "male") return "पुरुष";
    else return "अन्य";
  };

  handleAfterPrint = () => {
    this.setState({ beforePrint: false });
  };

  handleBeforePrint = () => {
    this.setState({ beforePrint: true });
  };

  getContent = () => {
    return this.setState({ beforePrint: true, component: this.myRef }, () => {
      return this.state.component;
    });
  };

  getToleNepaliName = () => {};

  render() {
    const { classes, title, rows, analysisList } = this.props;

    return (
      <div>
        <Grid item>
          <ReactToPrint
            style={{ marginTop: 200 }}
            onBeforePrint={this.handleBeforePrint}
            onAfterPrint={this.handleAfterPrint}
            trigger={() => (
              <Fab
                variant="extended"
                aria-label="Delete"
                className={classes.fab}
              >
                <PrintIcon className={classes.printIcon} />
              </Fab>
            )}
            data={this.state.component}
            content={this.getContent}
          />
        </Grid>
        <div ref={this.myRef}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                {!this.state.beforePrint && (
                  <CardHeader color="primary">
                    <Typography variant="h6" className={classes.cardTitleWhite}>
                      {title}
                    </Typography>
                  </CardHeader>
                )}
                <CardBody>
                  {/* Table here */}
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <Typography>क्र.स</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>घर नम्बर</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>वडा न.</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>toleName</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>घरमुलीको नाम</Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.length === 0 ? (
                        <TableRow>
                          <TableCell rowSpan={3} />
                          <TableCell colSpan={3}>दाता नभएको</TableCell>
                        </TableRow>
                      ) : (
                        rows.map((row, id) => (
                          <TableRow key={id}>
                            <TableCell component="th" scope="row">
                              {getNepaliNumber(id + 1)}
                            </TableCell>
                            <TableCell align="left">
                              {getNepaliNumber(
                                row.house.houseNumber && row.house.houseNumber
                              )}
                            </TableCell>
                            <TableCell align="left">
                              {getNepaliNumber(row.house.wardNumber)}
                            </TableCell>
                            <TableCell align="left">
                              {row.house.toleName}
                            </TableCell>
                            <TableCell align="left">{row.fullName}</TableCell>
                          </TableRow>
                        ))
                      )}
                      <TableRow />
                      {/* {count.length > 0 &&
                    count.map((each, i) => (
                      <TableRow>
                        {i === 0 && (
                          <TableCell rowSpan={count.length} colSpan={4} />
                        )}
                        <TableCell colSpan={2} component="th" scope="row">
                          {each.name}
                        </TableCell>
                        <TableCell align="left">{each.value}</TableCell>
                      </TableRow>
                    ))} */}
                    </TableBody>
                  </Table>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}

const styles = (theme) => ({
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflowX: "auto",
  },
});

export default withStyles(styles)(ToleFilterTable);
