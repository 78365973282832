import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import getNepalNumber from "../../../../../utils/getNepaliNumber";
import { no, selectedLanguage, yes } from "../../../../../variable/global";
import {
  familyTitle,
  waterSource,
  cookingSource,
  electricSource,
  toiletType,
  toiletQuestion,
  toiletTypeNo,
  anyBankAccount,
  numberofBankAccount,
  drinkingwaterplaceQuestion,
  waterPurificationQn,
  waterPurificationYes,
  homeAge,
  saltUsed,
  dirtyWaterQuestion,
  riskAreaAroundHouseQn,
  meansOfMunicipalInformationQn,
  wasteDisposal,
  hasinsur,
  insuranceDetail,
} from "../../../../../variable/houseSurvey";
import {
  QuestionAnswer,
  Title,
} from "../../../../common/frequent/QuestionAnswer";

const NoImageFound = require("../../../../../image/NoImageFound.jpg");

function mapValue(value, obj) {
  return obj[value];
}

class HouseIntro extends Component {
  getYesNo = (data) => (data ? yes[selectedLanguage] : no[selectedLanguage]);
  multimapValue = (name, object) =>
    name && name.map((each) => object[each]).join(",");
  render() {
    const { classes, houseNumber, secondaryHouseDetails } = this.props;
    return (
      <Grid item container direction="column" className={classes.container}>
        <Title title={familyTitle} />
        <Grid item container direction="column">
          {/* first */}
          <Grid
            item
            container
            direction="row"
            className={classes.eachQnAns}
            spacing={24}
          >
            <Grid item xs={4}>
              <QuestionAnswer
                question={waterSource[selectedLanguage]}
                answer={mapValue(secondaryHouseDetails.waterSource, {
                  tap_home: "पाइप धारा घरमा",
                  tap_public: "पाइप धारा सार्वजनिक",
                  handpump: "ट्युबवेल/हेन्डपम्प",
                  well_cover: "ढाकिएको इनार / कुवा",
                  well_uncover: "नढाकिएको इनार / कुवा",
                  main_water: "मुलको पानि",
                  river: "नदि खोला",
                })}
              />
            </Grid>
            <Grid item xs={4}>
              <QuestionAnswer
                question={drinkingwaterplaceQuestion[selectedLanguage]}
                answer={
                  secondaryHouseDetails.distanceToWaterSource &&
                  mapValue(secondaryHouseDetails.distanceToWaterSource, {
                    home_courtyard: "घर आँगनमै छ",
                    "10_min_walk": "१० मिनेट सम्मको पैदल",
                    half_hour_walk: "आधा घण्टा सम्मको दुरी",
                    "1_hour_walk": "१ घण्टा वा सो भन्दा माथि",
                  })
                }
              />
            </Grid>
            <Grid item xs={4}>
              <QuestionAnswer
                question={waterPurificationYes[selectedLanguage]}
                answer={this.getYesNo(
                  secondaryHouseDetails.waterPurification.hasWaterPurification
                )}
              />
            </Grid>
            {secondaryHouseDetails.waterPurification.hasWaterPurification && (
              <Grid item xs={4}>
                <QuestionAnswer
                  question={waterPurificationQn[selectedLanguage]}
                  answer={this.multimapValue(
                    secondaryHouseDetails.waterPurification
                      .waterPurificationYes,
                    {
                      boil: "उमालेर",
                      filter: "फिल्टर गरेर",
                      chemical: "औषधि हालेर",
                      solar: "सोडीस प्रविधी",
                    }
                  )}
                />
              </Grid>
            )}
            <Grid item xs={4}>
              <QuestionAnswer
                question={electricSource[selectedLanguage]}
                answer={this.multimapValue(
                  secondaryHouseDetails.electricSource,
                  {
                    electricity: "बिधुतलाईन",
                    kerosene: "मट्टीतेल/टुकी",
                    solar: "सोलार",
                    biogas: "वायो ग्यास",
                  }
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <QuestionAnswer
                question={cookingSource[selectedLanguage]}
                answer={this.multimapValue(
                  secondaryHouseDetails.cookingSource,
                  {
                    wood_general: "दाउरा/काठ(साधरण चुलो )",
                    wood_specific: "दाउरा/काठ(सुधारिएको चुलो )",
                    kerosene: "मट्टीतेल/स्टोभ",
                    lpg_gas: "एल.पी.जी.ग्यास",
                    bio_gas: "वायो ग्यास",
                    electricity: "विधुत",
                    solar: "सौर्य उर्जा",
                    guithaa: "गुइठा",
                  }
                )}
              />
            </Grid>
          </Grid>
          {/* end */}

          <Grid
            item
            container
            direction="row"
            className={classes.eachQnAns}
            spacing={24}
          >
            <Grid item xs={4}>
              <QuestionAnswer
                question={toiletQuestion[selectedLanguage]}
                answer={mapValue(secondaryHouseDetails.toilet.isToilet, {
                  yes: "छ",
                  no: "छैन (शौचालय नभएको )",
                  yes_but_not_us: "छ तर प्रयोग गरेको छैन",
                })}
              />
            </Grid>
            {secondaryHouseDetails.toilet.isToilet === "yes" && (
              <Grid item xs={4}>
                <QuestionAnswer
                  question={toiletType[selectedLanguage]}
                  answer={mapValue(secondaryHouseDetails.toilet.toiletYes, {
                    public_tank: "फ्लस भएको सार्वजनिक ढलमा मिसिने",
                    private_tank: "फ्लस भएको सेफ्टी ट्याङ्कमा मिसिने",
                    ordinary_tank: "साधारण",
                  })}
                />
              </Grid>
            )}
            {secondaryHouseDetails.toilet.isToilet === "no" && (
              <Grid item xs={4}>
                <QuestionAnswer
                  question={toiletTypeNo[selectedLanguage]}
                  answer={mapValue(secondaryHouseDetails.toilet.toiletNo, {
                    no_land: "जग्गा नभएर",
                    no_money: "बनाउने पैसा नभएर",
                    neighbour_use: "छिमेकीको प्रयोग गरेर",
                  })}
                />
              </Grid>
            )}
            <Grid item xs={4}>
              <QuestionAnswer
                question={anyBankAccount[selectedLanguage]}
                answer={this.getYesNo(secondaryHouseDetails.bank.bankAccount)}
              />
            </Grid>

            <Grid item container>
              {secondaryHouseDetails.bank.bankAccount && (
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell>पुरुष</TableCell>
                      <TableCell align="right">महिला</TableCell>

                      <TableCell align="right">तेस्रो लिङ्गी</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        {getNepalNumber(
                          secondaryHouseDetails.bank.bankAccountMale
                        )}
                      </TableCell>
                      <TableCell align="right">
                        {getNepalNumber(
                          secondaryHouseDetails.bank.bankAccountFemale
                        )}
                      </TableCell>

                      <TableCell align="right">
                        {getNepalNumber(
                          secondaryHouseDetails.bank.bankAccountThirdGender
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              )}
            </Grid>

            {/* <Grid item xs={4}>
              <QuestionAnswer
                question={homeAge[selectedLanguage]}
                answer={
                  secondaryHouseDetails.houseAge &&
                  `${getNepalNumber(secondaryHouseDetails.houseAge)} बर्ष`
                }
              />
            </Grid> */}
          </Grid>

          <Grid
            item
            container
            direction="row"
            className={classes.eachQnAns}
            spacing={24}
          >
            {/* <Grid item xs={4}>
              <QuestionAnswer
                question={saltUsed[selectedLanguage]}
                answer={
                  secondaryHouseDetails.saltUsed &&
                  mapValue(secondaryHouseDetails.saltUsed, {
                    iodine_salt: "आयोडिन्युक्त नून",
                    dhiko_salt: "ढिको नून",
                    open_salt: "खुल्ला नून",
                  })
                }
              />
            </Grid> */}

            {/* <Grid item xs={4}>
              <QuestionAnswer
                question={dirtyWaterQuestion[selectedLanguage]}
                answer={
                  secondaryHouseDetails.dirtyWaterManagement &&
                  this.multimapValue(
                    secondaryHouseDetails.dirtyWaterManagement,
                    {
                      gardenfiel: "करेसाबारीमा",
                      drain: "ढलमा मिसाएको",
                      ponding: "खाडलमा जम्मा गर्ने",
                      unmanaged: "अब्यबस्थित छाड्ने",
                    }
                  )
                }
              />
            </Grid> */}

            <Grid item xs={4}>
              <QuestionAnswer
                question={riskAreaAroundHouseQn[selectedLanguage]}
                answer={
                  secondaryHouseDetails.riskAreaAroundHouse &&
                  this.multimapValue(
                    secondaryHouseDetails.riskAreaAroundHouse,
                    {
                      earthquake: "भूकम्प",
                      flood: "बाढी",
                      landslide: "पहिरो",
                      hurricane: "हावाहुरी",
                      kataan: "ढुवान वा कटान",
                      no_risk: "जोखिम नभएको",
                    }
                  )
                }
              />
            </Grid>

            <Grid item xs={4}>
              <QuestionAnswer
                question={meansOfMunicipalInformationQn[selectedLanguage]}
                answer={
                  secondaryHouseDetails.meansOfMunicipalInformation &&
                  this.multimapValue(
                    secondaryHouseDetails.meansOfMunicipalInformation,
                    {
                      mobile_app: "मोबाइल एप",
                      radio: "रेडियो",
                      local_leader: "स्थानीय नेताहरुबाट",
                      newspaper_arti: "पत्रपत्रिका / समाचारपत्र",
                      government_off: "सरकारी अधिकारि",
                      nongov_officer: "गैर सरकारी कर्मचारिबाट",
                      information_ri: "सुचनाको हक प्रयोग गर्ने",
                      dont_know: "थाह छैन",
                    }
                  )
                }
              />
            </Grid>
            <Grid item xs={4}>
              <QuestionAnswer
                variant="h6"
                question={wasteDisposal[selectedLanguage]}
                answer={this.multimapValue(
                  secondaryHouseDetails.wasteDisposal,
                  {
                    field: "खेत बारीमा मलको रुपमा प्रयोग गर्ने",
                    fire: "संकलित फोहोर जलाउने",
                    biogas: "गोबर ग्यास प्लान्ट प्रायोग गर्ने",
                    wasteage: "अव्यवस्थित तबरले बाहिर जताभावी फ्याल्ने",
                    others: "अन्य",
                  }
                )}
              />
            </Grid>
            {/* <Grid item xs={4}>
              <QuestionAnswer
                question={hasinsur[selectedLanguage]}
                answer={this.getYesNo(secondaryHouseDetails.hasInsurance)}
              />
            </Grid> */}
          </Grid>

          {/* {secondaryHouseDetails.hasInsurance && (
            <Grid
              item
              container
              direction="column"
              className={classes.eachQnAns}
            >
              <QuestionAnswer question={insuranceDetail[selectedLanguage]} />
              <Grid item xs={6}>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      {secondaryHouseDetails.insurance &&
                        secondaryHouseDetails.insurance.map(each => (
                          <TableCell align="left">
                            {mapValue(each.name, {
                              life_insurance: "जिबन बिमा"
                            })}
                          </TableCell>
                        ))}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    <TableRow>
                      {secondaryHouseDetails.insurance &&
                        secondaryHouseDetails.insurance.map(each => (
                          <TableCell align="left">
                            {each.count && getNepalNumber(each.count)}
                          </TableCell>
                        ))}
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
          )} */}
        </Grid>
      </Grid>
    );
  }
}

const styles = (theme) => ({
  container: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 2,
  },
  table: {
    width: "auto",
  },
});

HouseIntro.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(HouseIntro);
